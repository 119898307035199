import getNewJwt from 'src/utils/apiJwt';

export const useJWT = () => {
  const refreshJwt = async () => {
    const refreshJwtResponse = await getNewJwt();
    if (refreshJwtResponse.status === 200) {
      localStorage.setItem('accessToken', refreshJwtResponse.data.accessToken);
      localStorage.setItem('teamId', refreshJwtResponse.data.teamId);
    }
  };
  return { refreshJwt };
}
