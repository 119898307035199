import React from 'react';
import { Grid } from '@material-ui/core';
import { InputField, SelectField } from '../../FormFields';

const states = [
  {
    value: 'VIC',
    label: 'Victoria'
  },
  {
    value: 'NSW',
    label: 'New South Wales'
  },
  {
    value: 'QLD',
    label: 'Queensland'
  },
  {
    value: 'WA',
    label: 'Western Australia'
  },
  {
    value: 'SA',
    label: 'South Australia'
  },
  {
    value: 'NT',
    label: 'Northern Territory'
  },
  {
    value: 'ACT',
    label: 'Australian Capital Territory'
  },
  {
    value: 'TAS',
    label: 'Tasmania'
  }
];

export default function PersonalDetails(props) {
  const {
    formField: { country, state, postCode, golfLinkNumber, handiCap }
  } = props;

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputField
            name={country.name}
            label={country.label}
            fullWidth
            disable={true}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectField
            name={state.name}
            label={state.label}
            data={states}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputField name={postCode.name} label={postCode.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField
            name={golfLinkNumber.name}
            label={golfLinkNumber.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputField name={handiCap.name} label={handiCap.label} fullWidth />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
