import React from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  helperText: {
    color: '#F44336',
    marginLeft: '14px'
  },
  labelText : {
    textAlign: 'left'
  },
  href: {
    color: '#000000'
  }
}));
export default function CheckboxLinkField(props) {
  const WpUrl = process.env.REACT_APP_WP_URL;
  const classes = useStyles();
  const { label, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const { setValue } = helper;
  const { value } = meta; //meta.value
  const privacyPolicyLink = `${WpUrl}/privacy-policy/`;
  const TermsLink = `${WpUrl}/terms/`;
  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return <FormHelperText className={classes.helperText}>{error}</FormHelperText>;
    }
  }

  function _onChange(e) {
    setValue(e.target.checked);
  }

  return (
    <FormControl {...rest}>
      <FormControlLabel
        value={value}
        checked={value}
        control={<Checkbox {...field} onChange={_onChange} />}
        className={classes.labelText}
        label={
          <div>
            I have read and agree to World Amateur 4BBB Champion's Tournament
            &nbsp;
            <a
              className={classes.href}
              target="_blank"
              href={privacyPolicyLink}
            >
              Privacy Policy
            </a>{' '}
            and &nbsp;
            <a
              className={classes.href}
              target="_blank"
              href={TermsLink}
            >
              Terms & Conditions
            </a>
            .
          </div>
        }
      />
      {_renderHelperText()}
    </FormControl>
  );
}
