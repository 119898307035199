import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chipBox: {
      "& .MuiChip-root": {
        borderRadius: 5
      }
    },
    paper: {
      minWidth: "685px",
      borderRadius: 20
    },
    formBox: {
      width: '425px',
    },
    img: {
      marginTop: '77px',
      width: '132px',
    },
    title: {
      marginTop: '33px',
      fontSize: '24px',
      fontWeight: 600,
      marginBottom: '30px'
    },
    errMsg: {
      color: 'red',
    },
    btnBox: {
      display: 'flex',
      justifyContent: 'space-around',
      marginBottom: '73px',
    },
    note: {
      margin: 'auto',
      width: '347px',
      marginTop: '33px',
      fontSize: '17px',
      marginBottom: '30px',
      textAlign: 'center'
    },
    box: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontFamily: 'Poppins-regular'
    },
    btn: {
      fontSize: 20,
      width: '155px',
      height: '53px',
      fontWeight: 600,
      backgroundColor: '#f8af41',
      border: 'none',
      borderRadius: 10,
      color: '#fff',
      padding: '10px 20px',
      cursor: 'pointer',
      transitionDuration: '0.4s',
      "&:hover": {
        backgroundColor: '#ef7d0f',
      },
    },
    btnL: {
      display: 'flex',
      justifyContent: 'center',
    },
    btnDisband: {
      fontSize: 20,
      width: '155px',
      height: '53px',
      fontWeight: 600,
      backgroundColor: '#333333',
      border: 'none',
      borderRadius: 10,
      color: '#fff',
      padding: '10px 20px',
      cursor: 'pointer',
      transitionDuration: '0.4s',
      "&:hover": {
        backgroundColor: '#1f1f1f',
      },
    },
    optBox: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    nameBox: {
      display: 'flex',
    },
  })
);

export default useStyles;
