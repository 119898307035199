import React from 'react';
import { Paper } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { useHistory } from 'react-router-dom';
import * as apiUtils from 'src/utils/apiUtils';
import ticket from 'src/assets/pngs/ticket.png';
import useStyles from './TeamMemberCart.style';

const TeamMemberCart = () => {
	const classes = useStyles();
	const history = useHistory();
	const discountedprice = '15';
	const originalprice = '25';
	const userId = localStorage.getItem('userId');
	const trailId = localStorage.getItem('trailId');
	const generateInvoice = async () => {
		const generateInvoiceResponse = await apiUtils.createInvoice(userId, trailId);
		if (generateInvoiceResponse.status === 200) {
			history.push('/auth/payment/invoice');
			console.log(generateInvoiceResponse.data);
		} else {
			console.log('error');
		}
	};
	return (
		<div className={classes.root}>
			<h1>My Payment</h1>
			<Paper className={classes.container}>
				<img src={ticket} alt="ticket" className={classes.ticket} />
				<div className={classes.cart}>
					<ShoppingCartIcon className={classes.carticon} />
					My Cart
				</div>
				<div className={classes.price}>
					<div className={classes.title}>Captain and Team Qualifying Round</div>
					<div className={classes.actualprice}>${discountedprice}</div>
				</div>
				<div className={classes.discount}>
					<div className={classes.title}>Team Member</div>
					<div className={classes.discountprice}>${originalprice}</div>
				</div>
			</Paper>
			<Paper className={classes.container}>
				<div className={classes.gst}>
					<div>GST</div>
					<div>The total price includes GST</div>
				</div>
				<div className={classes.total}>
					<div>TOTAL</div>
					<div>${discountedprice}</div>
				</div>
			</Paper>
			<button className={classes.btn} onClick={generateInvoice}>Generate Invoice</button>
		</div>
	);
};

export default TeamMemberCart;