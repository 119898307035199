import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& h1': {
        marginBottom: 40,
      }
    },
    container: {
      width: '50vw',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 15,
      marginBottom: 50,
    },
    image: {
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15,
      height: '20vh',
      objectFit: 'cover',
    },
    btn: {
      width: '150px',
      position: 'absolute',
      marginTop: 150,
      alignSelf: 'center',
      fontSize: 13,
      fontWeight: 600,
      backgroundColor: '#f8af41',
      border: 'none',
      borderRadius: 5,
      color: '#fff',
      padding: '10px 20px',
      cursor: 'pointer',
      transitionDuration: '0.4s',
      "&:hover": {
        backgroundColor: '#ef7d0f',
      },
    },
    type: {
      display: 'flex',
      justifyContent: 'center',
      color: '#511d62',
      fontWeight: 'bold',
      padding: 20,
    },
    disabledtype: {
      display: 'flex',
      justifyContent: 'center',
      color: '#808080',
      fontWeight: 'bold',
      padding: 20,
    },
  })
);

export default useStyles;