import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root:{
      fontFamily: 'Poppins-regular',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.2',
      letterSpacing: 'normal',
      textAlign:'left',
      backgroundColor: '#fff',
      borderRadius: '20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '380px',
      height: '550px',
      margin: '0 20px 20px 0',
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      padding: 15,
    },
    imgContainer: {
      marginBottom: 0
    },
    img: {
      width: '100%',
      height: '100%',
      borderTopLeftRadius: '20px',
      borderTopRightRadius: '20px',
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      // border: 'solid 2px black',
      height: '6vh',
      fontSize: 18,
      fontWeight: 600,
    },
    subTitle: {
      marginTop: 10,
      fontSize: 13,
    },
    btnContainer: {
      position: 'relative',
    },
    imgBtn: {
      position: 'relative',
      top: '50%',
      right: '50%',
      transform: 'translate(22%, 22%)',
      width: '50px',
      height: '50px',
      cursor: 'pointer',
    },
  }),
);
