/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Button,
  Typography,
  TextField,
  SvgIcon,
  IconButton,
} from '@material-ui/core';
import crossIcon from 'src/assets/svgs/crossIcon.svg';
import { ReactComponent as emailIcon } from 'src/assets/svgs/emailIcon.svg';
import { Link } from 'react-router-dom';
import { Form, Formik, Field } from 'formik';
import formConfig from './config';
import { useRecoveryPassword, useExtraFormConf } from './hooks';
import useStyles from './PasswordForgotForm.style';

const PasswordForgotForm = () => {
  const classes = useStyles();
  const { validationSchema, handleOnSubmit, warning } = useRecoveryPassword();
  const { getStartAdornment, getErrorMsg } = useExtraFormConf();
  const { emailInput } = formConfig;

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={handleOnSubmit}
      validationSchema={validationSchema}
    >
      {() => (
        <Form>
          <>
            {warning.shown
              ? (
                <div className={classes.warning} data-testid="warningMessage">
                  <img src={crossIcon} alt="crossIcon" />
                  <Typography className={classes.warning_message}>
                    {warning.message}
                  </Typography>
                </div>
              )
              : null}
          </>
          <Field
            {...emailInput}
            as={TextField}
            helperText={getErrorMsg('email')}
            InputProps={getStartAdornment(<SvgIcon component={emailIcon} viewBox="0 0 32 32" />)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.send_button}
          >
            Reset Password
          </Button>
        </Form>
      )}
    </Formik>
  );
};
export default PasswordForgotForm;
