import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Tab,
  Tabs,
  Typography,
  Box,
} from '@material-ui/core';
import TeamPanel from '../TeamPanel/TeamPanel';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box marginTop={5}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: '10px',
  },
}));

export default function TrailsTabs() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [trail, setTrail] = useState(0);
  if (trail === 0) {
    setTrail(1);
  }
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    setTrail(newValue + 1);
  };
  console.log(trail);

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        variant="fullWidth"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        <Tab label="The Captain Selection Trial 1" {...a11yProps(0)} />
        <Tab label="The Captain Selection Trial 2" {...a11yProps(1)} />
        <Tab label="The Captain Selection Trial 3" {...a11yProps(2)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <TeamPanel trail={trail} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TeamPanel trail={trail} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TeamPanel trail={trail} />
      </TabPanel>
    </div>
  );
}
