import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  forget_link: {
    fontSize: '0.875rem',
    textAlign: 'left',
    color: '#4d296f',
  },
  titlecontainer: {
    display: 'flex',
  },
  formaArea: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  signup_link: {
    fontFamily: 'Poppins-Medium',
    fontSize: '0.875rem',
    textAlign: 'left',
    color: '#4d296f',
    fontWeight: 550,
  },
  account: {
    fontFamily: 'Poppins-Medium',
    fontSize: '0.875rem',
    textAlign: 'left',
    color: '#828282',
    fontWeight: 550,
  },
  label: {
    fontFamily: 'Poppins-Medium',
    fontSize: '0.875rem',
    color: '#2d3748',
    fontWeight: 500,
  },
  error: {
    display: 'block',
  },
  img: {
    display: 'inline',
    float: 'left',
  },
  errormessage: {
    fontFamily: 'Poppins-SemiBold',
    color: '#eb5757',
    fontWeight: 600,
    fontSize: '0.875rem',
    marginLeft: '8px',
    display: 'inline',
  },
  btn: {
    fontSize: 13,
    fontWeight: 600,
    backgroundColor: '#f8af41',
    border: 'none',
    borderRadius: 5,
    color: '#fff',
    padding: '10px 20px',
    cursor: 'pointer',
    transitionDuration: '0.4s',
    "&:hover": {
        backgroundColor: '#ef7d0f',
    },
  },
  btnL: {
    display: 'flex',
    justifyContent: 'center',
  },
  textFieldContainer: {
    display: 'flex',
    // flexDirection: 'row',
    // flexWrap: 'wrap',
  },
  teamName: {
    margin: '0 20px 10px 0',
    display: 'flex',
    flexDirection: 'column',
    minWidth: 450,
  },
  grade: {
    margin: '0 10px 10px 0',
    display: 'flex',
    flexDirection: 'column',
    minWidth: 450,
  },
  title: {
    fontWeight: 600,
    marginBottom: 5,
  },
  subTitle: {
    marginBottom: 20,
  },
}));
