import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { at } from 'lodash';
import { useField } from 'formik';
import {TextField, FormHelperText} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as apiUtils from 'src/utils/apiUtils';
const useStyles = makeStyles(theme => ({
  helperText: {
    marginLeft: '14px'
  },
}));



function AutoCompleteBox(props) {
  const [club, setClub] = useState([])
  const classes = useStyles();
  const { label, setFieldValue, name, state, setState, ...rest} = props;
  const [field, meta] = useField(props);
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;
  function _renderHelperText() {
    if (isError) {
      return <FormHelperText className={classes.helperText}>{error}</FormHelperText>;
    }
  }
  const getAllClubs = async () => {
    const clubList = await apiUtils.getAllclubs();
    setClub(clubList.data)
    
  }
  useEffect(()=> {
    getAllClubs();
  }, []);


  return (
    <>
    <Autocomplete
     {...field}
     disableClearable ={true}
      fullWidth
      name={name}
      options={club}
      getOptionLabel={(option) => option.clubName}
      value={state}
      onChange={(e, value) => {
        if(value != null){
          setFieldValue("club", value.clubId);
        } 
        setState(value);
      }}
      renderInput={(params) => 
        <TextField
          {...params}
          fullWidth
          placeholder="please input your golf club" 
          id={name}
          name={name}
          label={label}
          variant="outlined"

      />}
    />
    {_renderHelperText()}
    </>
  );
}


export default AutoCompleteBox;
