import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainpageBody: {
      maxWidth: '1024px',
      display: 'flex',
      fontFamily: 'Poppins-regular',
      marginTop: 0,
      marginRight: 50,
      backgroundColor: '#f3f2f7',
      overflow: 'hidden',
      border: 'solid 2px black',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    leftpanel: {
      width: '75%',
      display: 'flex',
      flexDirection: 'column',
    },
    info: {
      width: '100%',
    },
    titlepanel: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    title: {
      lineHeight: 1,
      padding: 0,
      marginBottom: 0,
    }
  }),
);
