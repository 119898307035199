import React, { useState, useEffect } from 'react';
import * as apiUtils from 'src/utils/apiUtils';
import * as Yup from 'yup';

export const useMemberProfile = () => {
  const userId = localStorage.getItem('userId');
  const [club, setClub] = useState([]);
  const [memberProfile, setMemberProfile] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    state: '',
    postcode: '',
    golfLinkNumber: '',
    handicap: '',
    grade: '',
    clubId: '',
    clubName: '',
    hasTeam: false,
    gender: '',
    country: 'Australia',
  });
  const { firstName, lastName, email, phoneNumber, state, postcode, clubId, clubName, gender } = memberProfile;
  const initValues = {
    firstName,
    lastName,
    email,
    phoneNumber,
    state,
    postCode: postcode,
    club: clubId,
    gender,
    country: 'Australia',
  };
  const initClub = {
    clubId,
    clubName,
  };
  const getAllClubs = async () => {
    const clubList = await apiUtils.getAllclubs();
    setClub(clubList.data);
  };

  const getMemberProfile = async () => {
    const memberProfileResponse = await apiUtils.getUserProfile(userId);
    if (memberProfileResponse.status === 200) {
      const { data } = memberProfileResponse;
      setMemberProfile({
        ...memberProfile,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phoneNumber: data.phoneNumber,
        state: data.state,
        postcode: data.postcode,
        golfLinkNumber: data.golfLinkNumber,
        handicap: data.handicap,
        grade: data.level,
        clubId: data.clubId,
        clubName: data.club,
        hasTeam: data.hasTeam,
        gender: data.gender,
      });
    }
  };
  const validate = Yup.object({
    firstName: Yup.string()
      .trim()
      .required('First name is required')
      .max(255, 'Your name input is too long!'),
    lastName: Yup.string()
      .trim()
      .required('Last name is required')
      .max(255, 'Your name input is too long!'),
    state: Yup.string()
      .nullable()
      .required(`State is required`),
    postCode: Yup.string()
      .nullable()
      .required(`postal code is required`)
      .matches(/^[1-9]{1}[0-9]{3}$/, 'Postal code must be 4 digitals number(from 1000 - 9999)'),
  })

  useEffect(() => {
    getMemberProfile();
    getAllClubs();
  }, []);

  return { memberProfile, validate, club, userId, initValues, initClub };
};
