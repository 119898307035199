import React, { useState, useEffect } from 'react';
import { render } from "react-dom";
import jsPDF from "jspdf";
import { Paper, Checkbox, Dialog, } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import WarningIcon from '@material-ui/icons/Warning';
import { useInvoiceDetails } from './hooks';
import download from 'src/assets/download.svg';
import CloseIcon from 'src/assets/pngs/icons/close-button.png';
import Popup from './components/Popup/Popup';
import useStyles from './Invoice.style';

const Invoice = () => {
	const classes = useStyles();
	const { email, userId, status, invoice, getInvoice, downloadPDF, handleChecked, handleChange, emailInvoice, open, handleClose } = useInvoiceDetails();

	return (
		<div className={classes.root}>
			<h1>My Payment</h1>
			{ !invoice.invoiceNumber ?
				<Paper className={classes.info}>Please click My Team to form or join a team.</Paper> :
				<>
					<Paper className={classes.container}>
						<div className={classes.invoice}>
							<DescriptionIcon className={classes.icon} />
							My Invoice
						</div>
						<div className={classes.detail}>
							<dl>
								<dt>Invoice:</dt>
								<dd>{invoice.invoiceNumber}</dd>
								<p>
									{invoice.description}
								</p>
								<dt>Due Date:</dt>
								<dd>09/01/2022</dd>
							</dl>
							<div className={classes.status}>
								<WarningIcon />
								<div>
									Status <br />
									<span>{status}</span>
								</div>
							</div>
							<div className={classes.download} onClick={downloadPDF}>
								<img src={download} alt="download" className={classes.downloadicon} />
									Download
							</div>
						</div>
					</Paper>
					<p className={classes.notice}>
						Payment status may take up to 3 business days to update. <br /> 
						If you have any questions, please contact <span>info@wact4bbb.com</span>.
					</p>
					<h2>Send Invoice</h2>
					<Paper className={classes.sendcontainer}>
						<div className={classes.email}>
							<dl>
								<div>
									<dt><Checkbox disabled checked inputProps={{ 'aria-label': 'disabled checked checkbox' }} /></dt>
									<dd>
										<span>Your Email</span><br />
										{email}
									</dd>
								</div>
								<div>
									<dt><Checkbox inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} onChange={handleChecked} /></dt>
									<dd><input type="text" name="email" placeholder="Other Email Address" onChange={handleChange} /></dd>
								</div>
							</dl>
						</div>
						<button className={classes.btn} onClick={emailInvoice}>Send e-invoice</button>
						<Dialog
			        open={open}
			        onClose={handleClose}
			        aria-labelledby="alert-dialog-title"
			        aria-describedby="alert-dialog-description"
			      >
							<Popup handleClose={handleClose} />
						</Dialog>
					</Paper>
				</>
			}		
		</div>
	);
};

export default Invoice;
