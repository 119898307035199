import Calendar from 'react-calendar';
import './Calendar.css';
import { useStyles } from './Calendars.style';

const Calendars = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.datefield}>
        <Calendar
          className={classes.calendar}
        />
      </div>
    </>
  );
};

export default Calendars;
