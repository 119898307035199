import React, { useState, useContext } from 'react';
import { IconButton } from '@material-ui/core';
import { useStyles } from './Notifications.style';
import Notify from './components/Notify/Notify';
import { TeamContext } from 'src/pages/TeamPage/TeamContent';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import NotificationDialog from './dialog/NotificationDialog';
import { useDialog } from './dialog/useDialog';

const Notifications = () => {
  const classes = useStyles();
  const { setUpdate, userInvitationNoti, getInvitationNotificationByUser } = useContext(TeamContext);
  const [open, setOpen] = useState(false);
  const [dialogInfo, setDialogInfo] = useState("");
  const { freezeTime } = useDialog(open);

  return (
    <>
      <div>
        <div className={classes.titleContainer}>
          <h2>Team Notifications</h2>
          <div className={classes.btnContainer}>
            <IconButton className={classes.btn}><KeyboardArrowLeftIcon className={classes.btn} /></IconButton>
            <IconButton className={classes.btn}><KeyboardArrowRightIcon className={classes.btn} /></IconButton>
          </div>
        </div>
        <div className={classes.notify}>
          {userInvitationNoti?.map((item, index) => (
            <Notify
              key={index}
              id={item.id}
              teamId={item.teamId}
              inviterId={item.inviterId}
              inviteeId={item.inviteeId}
              teamName={item.teamName}
              inviterFirstName={item.inviterFirstName}
              inviteeFirstName={item.inviteeFirstName}
              getInvitationNotificationByUser={getInvitationNotificationByUser}
              setUpdate={setUpdate}
              setDialogInfo={setDialogInfo}
              setOpen={setOpen}
            />
          ))}
        </div>
      </div>
      <NotificationDialog
        open={open}
        setOpen={setOpen}
        dialogInfo={dialogInfo}
        setDialogInfo={setDialogInfo}
        freezeTime={freezeTime}
      />
    </>
  )
}

export default Notifications;
