import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => createStyles({
  error: {
    display: 'block',
  },
  errormessage: {
    fontFamily: 'Poppins-SemiBold',
    color: '#eb5757',
    fontWeight: 600,
    fontSize: '0.875rem',
    marginLeft: '8px',
    display: 'inline',
  },
  reset_button: {
    textTransform: 'initial',
    marginTop: '2rem',
  },
  label: {
    fontSize: '14px',
    color: '#828282',
    fontWeight: 500,
    marginBottom: '1rem',
  },
  notification_img: {
    display: 'inline',
    float: 'left',
  },
  warning: {
    margin: '20px 0',
    padding: '3%',
    borderRadius: '10px',
    border: 'solid 2px #eb5757',
    backgroundColor: '#fcebea',
    display: 'flex',
    alignItems: 'center',
  },
  warning_message: {
    fontWeight: 500,
    marginLeft: '16px',
    textAlign: 'left',
    color: '#eb5757',
  },
  change_password: {
    fontWeight: 600,
    fontFamily: 'Poppins-Bold',
    fontSize: '0.9rem',
    marginBottom: '8%',
  },
  img: {
    display: 'block',
    width: '100%',
    height: 300,
  },
  dialog_content: {
    marginTop: '5%',
    marginBottom: '15%',
    marginLeft: '12%',
    marginRight: '12%',
  },
  button_area: {
    textTransform: 'initial',
    marginTop: '5%',
  },
}));
