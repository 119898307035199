import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: 30,
    },
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: theme.spacing(0),
    },
    title: {
      fontFamily: 'Poppins-regular',
      color: '#333',
      fontWeight: 600,
      letterSpacing: 6.8,
      fontSize: '35px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '27px',
      },
    },
    formTeam: {
      marginTop: '1px',
      paddingRight: theme.spacing(10),
      marginLeft: '56px',
    },
    right: {
      paddingLeft: theme.spacing(10),
    },
}));
