import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    columnBox: {
      display: 'flex',
     
      padding:'10px',
    },
    
    
    
  }),
);
