import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PaymentPage from 'src/pages/PaymentPage/PaymentPage';
import NominatedCaptainPayment from 'src/pages/PaymentPage/components/NominatedCaptainPayment/NominatedCaptainPayment';
import TeamMemberPayment from 'src/pages/PaymentPage/components/TeamMemberPayment/TeamMemberPayment';
import NominatedCaptainCart from 'src/pages/PaymentPage/components/NominatedCaptainCart/NominatedCaptainCart';
import TeamMemberCart from 'src/pages/PaymentPage/components/TeamMemberCart/TeamMemberCart';
import Invoice from 'src/pages/PaymentPage/components/Invoice/Invoice';

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  return (
    <>
      <Route
        {...rest}
        render={
          (props) => (
            localStorage.getItem('accessToken')
              ? <Component {...props} />
              : <Redirect to={{ pathname: '/login' }} />
          )
        }
      />
    </>
  )
};

const PaymentLayouts = () => {
  return (
    <>
      <PrivateRoute path="/auth/payment/paymentselect" component={PaymentPage} />
      <PrivateRoute path="/auth/payment/payment-captain" component={NominatedCaptainPayment} />
      <PrivateRoute path="/auth/payment/payment-member" component={TeamMemberPayment} />
      <PrivateRoute path="/auth/payment/cart-captain" component={NominatedCaptainCart} />
      <PrivateRoute path="/auth/payment/cart-member" component={TeamMemberCart} />
      <PrivateRoute path="/auth/payment/invoice" component={Invoice} />
    </>
  );
};
export default PaymentLayouts;