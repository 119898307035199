import React from 'react';
import {
  Typography,
  Grid,
  Hidden,
  Container,
} from '@material-ui/core';
import {
  Link,
} from 'react-router-dom';
import caption from 'src/assets/pngs/password_forgot_page_caption.png';
import useStyles from './PasswordForgotPage.style';
import ForgotPasswordForm from './components/PasswordForgotForm';

const PasswordForgotPage = () => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
        <Typography component="h1" variant="h4" align="center" className={classes.title}>
            Forgot your password?
          </Typography>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item md={5} >
          <ForgotPasswordForm />
        </Grid>
        <Hidden smDown>
          <Grid item md={5} container className={classes.img_container}>
            <img src={caption} alt="forgetPassword" className={classes.background_img} />
          </Grid>
        </Hidden>
      </Grid>
    </Container>
  );
};

export default PasswordForgotPage;
