import React, { useContext } from 'react';
import {
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TeamHeader from './components/TeamsHeader/TeamHeader';
import TrailsTabs from './components/TrailsTabs/TrailsTabs';
import { TeamContext } from '../TeamContent';

const useStyles = makeStyles((theme) => ({
  container: {
    marginRight: 20
  },
}));


const memberPage = () => {
  const classes = useStyles();
  const { teamInfo } = useContext(TeamContext);
  const { teamName } = teamInfo;
  return (
    <>
      <div className={classes.container}>
        <div>
          <Grid container >
            <Grid item xs={12}><TeamHeader teamName={teamName}/></Grid>
            <Grid item xs={12}><TrailsTabs /></Grid>
          </Grid>
        </div>
      </div>
    </>
  )
}

export default memberPage;
