import React from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import { TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

export default function PasswordField(props) {
  const { errorText, disable, ...rest } = props;
  const [field, meta] = useField(props);

 
  const [showPassword, setShowPassword] = React.useState(false);


  const handleShowPassword = () => {
    setShowPassword(show => !show);
  };

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }
  }

  return (
    <TextField
      disabled={disable}
      variant="outlined"
      type={showPassword ? 'text' : 'password'}
      error={meta.touched && meta.error && true}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleShowPassword} edge="end">
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
      helperText={_renderHelperText()}
      {...field}
      {...rest}
    />
  );
}