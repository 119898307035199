import React from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import CloseIcon from 'src/assets/pngs/icons/close-button.png';
import useStyles from './Popup.style';

const Popup = (props: any) => {
	const classes = useStyles();
	const { handleClose } = props;
	return (
		<>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText id="alert-dialog-description" className={classes.dialogContentText}>
          An email has been sent to the email address(es) given. Please check your inbox.
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.btncontainer}>
      	<img className={classes.closebtn} src={CloseIcon} alt="close icon" onClick={handleClose} />
      </DialogActions>
		</>
	);
};

export default Popup;
