import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    promotioncontainer: {
      display: 'flex',
      justifyContent: 'space-around',
      marginTop: 15,
      flexWrap: 'wrap',
    },
    singlepromotion: {
      wdth: 300,
      height: 100,
      textAlign: 'center',
      border: 'solid 2px lightgrey',
      marginRight: 10,
      marginBottom: 20,
      borderRadius: 10,
      paddingTop: 40,
      paddingLeft: 70,
      paddingRight: 70,
    }
  }),
);
