import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    width: 440,
    backgroundColor: '#fff',
    display:'flex',
    flexDirection: 'row',
    padding: '20px 60px 20px 20px',
    margin: '10px 20px 10px 0',
    borderRadius: 20,
    fontSize: 15,
  },
  img: {
    width: 50,
    height:50,
  },
  container: {
    padding: '0 50px 0 30px',
    display: 'flex',
    flexDirection: 'column',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontWeight: 600,
  },
  msg: {},
  btnContainer: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
  },
  acpBtn: {
    fontSize: 11,
    fontWeight: 600,
    marginRight: 10,
    backgroundColor: '#f8af41',
    border: 'none',
    borderRadius: 5,
    color: '#fff',
    padding: '7px 25px',
    cursor: 'pointer',
    transitionDuration: '0.4s',
    "&:hover": {
        backgroundColor: '#ef7d0f',
    }
  },
  rejBtn: {
    fontSize: 11,
    fontWeight: 600,
    backgroundColor: '#333333',
    border: 'none',
    borderRadius: 5,
    color: '#fff',
    padding: '7px 25px',
    cursor: 'pointer',
    transitionDuration: '0.4s',
    "&:hover": {
        backgroundColor: '#1f1f1f',
    }
  },
}),);
