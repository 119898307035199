import * as React from 'react';
import { Divider } from '@material-ui/core';
import { useStyles } from './SingleCompetition.style';
import placeholder from 'src/assets/placeholder.jpg';
import ArrowIcon from 'src/assets/pngs/icons/dashboard/arrowIcon.png';

interface SingleCompetitionProps {
  competition: {
    status: string,
    team: string,
    startdate: string,
  }
}

const SingleCompetition = ({ competition }: SingleCompetitionProps) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <div className={classes.statusContainer}>
            <span className={classes.status}>Registered</span>
        </div>
        <img className={classes.imgTitle} src={placeholder} alt="img" />
        <div className={classes.container}>
        <div className={classes.titleContainer}>
          <span className={classes.title}>The State Australian Selection Trial 2022</span>
        </div>
        <Divider />
        <div className={classes.titleContainer}>
          <span className={classes.subTitle}>Status</span>
          <span className={classes.info}>The Captain Qualifying Trial {competition.status}</span>
        </div>
        <div className={classes.titleContainer}>
          <span className={classes.subTitle}>Team Status</span>
          <span className={classes.info}>{competition.team}</span>
        </div>
        <div className={classes.dateContainer}>
          <div className={classes.date}>
            <span className={classes.subTitle}>Start Date</span>
            <span className={classes.info}>{competition.startdate}</span>
          </div>
          <div className={classes.btn}>
            <img className={classes.imgBtn} src={ArrowIcon} alt="view more" />
          </div>
        </div>
        </div>
      </div>
    </>
  )
}

export default SingleCompetition;