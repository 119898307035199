/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  Link,
  Typography,
} from '@material-ui/core';
import * as apiUtils from '../../../../utils/apiUtils';
import useStyles from './ResendMessage.style';
import { useHistory } from 'react-router-dom';

const ResendMessage = () => {
  const classes = useStyles();
  const history = useHistory();
  const handleResend = () => {
    const email = localStorage.getItem('email');
    apiUtils.resendActivationLink({
      email,
    });
    history.push('/activation-email'); 
  };
  return (
    <div className={classes.resend}>
      <Typography className={classes.resend_message}>Please activate your account.</Typography>
      <Link
        component="button"
        onClick={handleResend}
        className={classes.resend_link}
      >
        Click here to resend verification email
      </Link>
    </div>
  );
};

export default ResendMessage;
