import checkoutFormModel from './checkoutFormModel';
const {
  formField: {
    firstName,
    lastName,
    gender,
    phoneNumber,
    postCode,
    country,
    checkOver18,
    state,
    handiCap,
    golfLinkNumber,
    emailAddress,
    confirmEmailAddress,
    password,
    confirmPassword,
    checkReceive,
    checkAgree,
    club,
  }
} = checkoutFormModel;

export default {
  [firstName.name]: '',
  [lastName.name]: '',
  [gender.name]: '',
  [phoneNumber.name]: '',
  [postCode.name]: '',
  [checkOver18.name]: false,
  [country.name]: 'Australia',
  [state.name]: '',
  [handiCap.name]: '',
  [golfLinkNumber.name]: '',
  [emailAddress.name]: '',
  [confirmEmailAddress.name]: '',
  [password.name]: '',
  [confirmPassword.name]: '',
  [checkReceive.name]: false,
  [checkAgree.name]: false,
  [club.name]:''
};
