export default {
  formId: 'checkoutForm',
  formField: {
    firstName: {
      name: 'firstName',
      label: 'First name*',
      requiredErrorMsg: 'First name is required'
    },
    lastName: {
      name: 'lastName',
      label: 'Last name*',
      requiredErrorMsg: 'Last name is required'
    },
    gender: {
      name: 'gender',
      label: 'Gender*',
      requiredErrorMsg: 'Gender is required'
    },
    phoneNumber: {
      name: 'phoneNumber',
      label: 'PhoneNumber'
    },
    club: {
      name: 'club',
      label: 'Golf Club*',
      requiredErrorMsg: 'Golf club is required'
    },
    golfLinkNumber: {
      name: 'golfLinkNumber',
      label: 'Golf Link Number*',
      requiredErrorMsg: 'Golf Link Number is required',
      invalidErrorMsg: 'Golf Link Number must be 10 digitals number'
    },
    state: {
      name: 'state',
      label: 'State, Province, or Region*',
      requiredErrorMsg: 'State is required'
    },
    postCode: {
      name: 'postCode',
      label: 'Postal code*',
      requiredErrorMsg: 'Postal code is required',
      invalidErrorMsg: 'Postal code must be 4 digitals number(from 1000 - 9999)'
    },
    country: {
      name: 'Country',
      label: 'Country or Region*',
      requiredErrorMsg: 'Country is required'
    },
    checkOver18: {
      name: 'checkOver18',
      label:
        'By Checking the box, you are confirming that you are over 18 years old.',
      requiredErrorMsg: 'Please click it'
    },
    handiCap: {
      name: 'handiCap',
      label: 'Handicap*',
      requiredErrorMsg: 'Handicap is required',
      invalidErrorMsg: 'Please enter a valid handicap'
    },
    emailAddress: {
      name: 'email',
      label: 'Email address*',
      requiredErrorMsg: 'Email required',
      invalidErrorMsg: 'Please input a correct Email address'
    },
    confirmEmailAddress: {
      name: 'confirmEmail',
      label: 'Confirm Email address*',
      requiredErrorMsg: 'Email required',
      invalidErrorMsg: 'Your Email address does not match'
    },
    password: {
      name: 'password',
      label: 'Password*',
      requiredErrorMsg: 'Password required',
      invalidErrorMsg:
        'Your password should at least contain 8 characters with 1 alphabet and 1 non-letter character'
    },
    confirmPassword: {
      name: 'confirmPassword',
      label: 'Confirm password*',
      requiredErrorMsg: 'Confirm Password',
      invalidErrorMsg: 'Your password does not match'
    },
    checkReceive: {
      name: 'checkReceive',
      label:
        "Yes, I'd like to receive golf tour offers and exclusive promotions from World Amateur 4BBB Champion's Tournament."
    },
    checkAgree: {
      name: 'checkAgree',
      label:
        "I have read and agree to World Amateur 4BBB Champion's Tournament Privacy Policy and Terms & Conditions."
    }
  }
};
