/* eslint-disable react/no-unused-state */
/*  eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import TournamentSelectPage from 'src/pages/TeamPage/TournamentSelectPage/TournamentSelectPage';
import FormTeamPage from 'src/pages/TeamPage/FormTeamPage/FormTeamPage';
import MemberPage from 'src/pages/TeamPage/MemberPage/MemberPage'
import { TeamContextProvider } from 'src/pages/TeamPage/TeamContent';

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  return (
    <>
      <Route
        {...rest}
        render={
          (props) => (
            localStorage.getItem('accessToken')
              ? <Component {...props} />
              : <Redirect to={{ pathname: '/login' }} />
          )
        }
      />
    </>
  )
};

const TeamLayout = () => {
  return (
    <>
      <TeamContextProvider>
        <>
          <PrivateRoute path="/auth/team/teamselect" component={TournamentSelectPage} />
          <PrivateRoute path="/auth/team/form" component={FormTeamPage} />
          <PrivateRoute path="/auth/team/teaminfo" component={MemberPage} />
        </>
      </TeamContextProvider>
    </>
  );
};
export default TeamLayout;
