import React from 'react';
import { useStyles } from './DashboardPage.style';
import UpcomingCompetitions from './components/UpcomingCompetitions/UpcomingCompetitions';
import Notifications from './components/Notifications/Notifications';

const DashboardPage = () => {
  const classes = useStyles();

  return (
    <>
      <span className={classes.title}>DASHBOARD</span>
      <UpcomingCompetitions />
      <Notifications />
    </>
  );
};

export default DashboardPage;
