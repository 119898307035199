import React from 'react';
import useStyles from './Footer.style';
import { Link } from 'react-router-dom'

interface FooterProps {
  isFullWidth?: Boolean;
}
const Footer = ({isFullWidth = true}: FooterProps) => {
  const WpUrl = process.env.REACT_APP_WP_URL;
  const classes = useStyles(isFullWidth);
  const privacyPolicyLink = `${WpUrl}/privacy-policy/`;
  const TermsLink = `${WpUrl}/terms/`;

  return (
    <footer className={classes.foot}>
      <div className={classes.copyRight}>
        Copyright © 2021 World Amateur 4BBB Champion's Tournament PTY LTD. ABN: 18644447656
      </div>
      <div className={classes.link}>
        <a className={classes.herf}  target="_blank" href={privacyPolicyLink}>
          <div className={classes.item}>Privacy Policy</div>
        </a>
        <a className={classes.herf}  target="_blank" href={TermsLink}>
          <div className={classes.item}>Website Terms of Service</div>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
