import React from 'react';
import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { useStyles } from './Header.style';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import logo from 'src/assets/pngs/site-logo.png';

const Header = () => {
  const WpUrl = process.env.REACT_APP_WP_URL;
  const listItems = [
    { id: 0, name: 'Home', href: WpUrl },
    { id: 1, name: 'Join the Tournament', href: `${WpUrl}/tournament-2022/` },
    { id: 2, name: 'Members', href: '/auth/dashboard' },
    { id: 3, name: 'Sign Up', href: '/signup' },
    { id: 4, name: 'Login', href: '/login' },
  ];
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar color="secondary" elevation={0}>
      <Toolbar className={classes.header}>
        <Link to="/" className={classes.link}>
          <img src={logo} alt="site-logo" className={classes.img} />
        </Link>
        <Box className={classes.header_item}>
          {listItems.map((item) => (
            <a href={item.href} key={item.id} className={classes.tab}>
              {item.name}
            </a>
          ))}
        </Box>
        <Box className={classes.menu}>
          <Box className={classes.menu_button} onClick={handleClick} id="basic-button" aria-controls="basic-menu">
            <svg
              className="ct-trigger ct-icon"
              width="18"
              height="14"
              viewBox="0 0 18 14"
              aria-label="Off-canvas trigger icon"
              data-type="type-1"
            >
              <rect y="0.00" width="18" height="1.7" rx="1"></rect>
              <rect y="6.15" width="18" height="1.7" rx="1"></rect>
              <rect y="12.3" width="18" height="1.7" rx="1"></rect>
            </svg>
            <span className={classes.menu_text}>MENU</span>
          </Box>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {listItems.map((item) => (
              <MenuItem key={item.id} onClick={handleClose}>
                <a href={item.href} className={classes.menu_item}>{item.name}</a>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default Header;
