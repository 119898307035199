import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  login_link: {
    fontFamily: 'Poppins-Medium',
    fontSize: '0.875rem',
    textAlign: 'left',
    color: '#4d296f',
    fontWeight: 550,
  },
}));


function SignUpSuccess() {
  const classes = useStyles();
  const WpUrl = process.env.REACT_APP_WP_URL;
  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
      Sign up successfully
      </Typography>

      <a href={WpUrl} className={classes.login_link} >
                      Back to Home page
                    </a>
    </React.Fragment>
  );
}

export default SignUpSuccess;
