import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins-Regular',
  },
  palette: {
    primary: {
      main: '#7d589f',
    },
    secondary: {
      main: '#ef7d0f',
    },
  },
  overrides: {
    MuiFormHelperText: {
      root: {
        color: '#f44336',
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 690, // tablet
      md: 1000, // desktop
      lg: 1440, //
      xl: 1920,
    },
  },
});

export default theme;
