/*  eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import Login from '../components/Login/Login';
import theme from './theme';
import Header from 'src/components/Header/components/Header';
import Footer from 'src/components/Footer/Footer';
import SignupContainer from '../components/Signup/SignupContainer';
import ActivationEmailPage from '../components/Signup/components/ActivationEmailPage';
import Auth from '../components/Auth/Auth';
import VerifiedPage from '../components/Signup/components/VerifiedPage/VerifiedPage';
import PasswordForgotPage from 'src/pages/PasswordPage/PasswordForgotPage';
import PasswordResetPage from 'src/pages/PasswordPage/PasswordResetPage';
import PasswordEmailPage from 'src/pages/PasswordPage/PasswordEmailPage';

const PrivateRoute = ({ component: Component, ...rest }: any) => (
  <>
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem('accessToken') ? <Component {...props} /> : <Redirect to={{ pathname: '/login' }} />
      }
    />
  </>
);

const PublicRoute = ({ component: Component, ...rest }: any) => (
  <div>
    <Header />
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem('accessToken') ? <Redirect to={{ pathname: '/auth/dashboard' }} /> : <Component {...props} />
      }
    />
    <Footer />
  </div>
);
// TODO: add NOTFOUND page
const App = () => (
  <>
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <PublicRoute exact path="/" component={Login} />
          <PublicRoute path="/login" component={Login} />
          <PublicRoute path="/signup" component={SignupContainer} />
          <PublicRoute path="/activation-email" component={ActivationEmailPage} />
          <PublicRoute path="/verifylink" component={VerifiedPage} />
          <PublicRoute path="/password" component={PasswordForgotPage} />
          <PublicRoute path="/reset-password" component={PasswordResetPage} />
          <PublicRoute path="/password-email" component={PasswordEmailPage} />
          <PrivateRoute path="/auth" component={Auth} />
        </Switch>
      </Router>
    </ThemeProvider>
  </>
);

export default App;
