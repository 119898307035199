import React from 'react';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { useStyles } from './SingleNotification.style';

const SingleNotification = () => {
  const classes = useStyles();
  return (
    <div className={classes.notificationContainer}>
      <div className={classes.iconPosition}>
        <NotificationsIcon className={classes.icon} />
      </div>
      <div className={classes.info}>
        <span className={classes.title}>Team Invitation</span>
        <span className={classes.details}>Sally invited you to join Async</span>
      </div>
    </div>
  );
};

export default SingleNotification;
