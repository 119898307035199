import React from 'react';
import { Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import golfBackground from 'src/assets/golfBackground.jpg';
import useStyles from './TeamMemberPayment.style';

const TeamMemberPayment = () => {
	const classes = useStyles();
	const history = useHistory();
	const addCart = () => {
		history.push('/auth/payment/cart-member');
	};
	return (
		<div className={classes.root}>
			<h1>My Payment</h1>
			<Paper className={classes.container}>
				<img src={golfBackground} alt={golfBackground} className={classes.image} />
				<button className={classes.btn} onClick={addCart}>Add to Cart</button>
				<div className={classes.type}>Pay for the Captain and Team Qualifying Round</div>
			</Paper>

			<Paper className={classes.container}>
				<img src={golfBackground} alt={golfBackground} className={classes.image} />
				<div className={classes.disabledtype}>Pay for the Captain Qualifying Round</div>
			</Paper>
		</div>
	);
};

export default TeamMemberPayment;