import React from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import MainColumn from './components/MainColumn/MainColumn';
import { Formik, Form } from 'formik';
import * as apiUtils from 'src/utils/apiUtils';
import { useMemberProfile } from '../ProfilePage/hooks';
import { useStyles } from './ProfileEditPage.style';
import IUpdateProfileValue from 'src/common/interfaces/IUpdateProfileValue';

const ProfileEditPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { validate, club, userId, initValues, initClub } = useMemberProfile();
  const updateDetails = async (values: IUpdateProfileValue) => {
    const { club, firstName, gender, lastName, phoneNumber, postCode, state } = values;
    const updateProfileResponse = await apiUtils.updateUserProfile(userId, { club, firstName, gender, lastName, phoneNumber, postCode, state });
    if (updateProfileResponse.status === 200) {
      history.push('/auth/profile');
    } else {
      console.log('error');
    }
  };

  return (
    <div className={classes.profilePage}>
      <div className={classes.profilePageBody}>
        <div className={classes.titleLine}>
          EDIT PROFILE
        </div>
        <Formik
          //todo fake data
          initialValues={initValues}
          validationSchema={validate}
          enableReinitialize
          onSubmit={values => {
            updateDetails(values);
          }}
        >
          {({ setFieldValue }) => {
            return (
              <div className={classes.maincontainer}>
                <Form>
                  <MainColumn setFieldValue={setFieldValue} hasTeam={false} club={club} initClub={initClub} />
                  <Button className={classes.button} type="submit">Save</Button>
                </Form>
              </div>
            )
          }}
        </Formik>
      </div>
    </div>
  );

};

export default ProfileEditPage;
