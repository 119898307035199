import { useState, useEffect, useRef } from 'react';

export const useDialog = (open: boolean) => {
  const [freezeTime, setFreezeTime] = useState(5);

  useEffect(() => {
    let id = setInterval(() => {
      if (open) {
        setFreezeTime(v => {
          return v - 1;
        });
      }
    }, 1000);
    return () => clearInterval(id);
  }, [open]);

  return { freezeTime };
};
