import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { at } from 'lodash';
import { useField } from 'formik';
import { TextField, FormHelperText } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    marginBottom: '15px',
  },
  helperText: {
    marginLeft: '14px'
  },
  paper: {
    width: '100%',
  },
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#511d62"
    },
  },
}));

const AutoCompleteBox = (props: any) => {
  const { initClub, label, setFieldValue, name, club, hasTeam } = props;
  const [data, setData] = useState<any>(initClub);
  const classes = useStyles();
  const [field, meta] = useField(props);
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;
  function _renderHelperText() {
    if (isError) {
      return <FormHelperText className={classes.helperText}>{error}</FormHelperText>;
    }
  }

  return (
    <>
      <div className={classes.label}>{label}</div>
      <Autocomplete
        {...field}
        className={classes.root}
        classes={{ paper: classes.paper }}
        disabled={hasTeam}
        disableClearable={true}
        fullWidth
        options={club}
        getOptionLabel={(option) => option.clubName}
        value={data.clubId ? data : initClub}
        onChange={(e, value) => {
          setFieldValue("club", value.clubId);
          setData(value);
        }}
        renderInput={(params) =>
          <TextField
            {...params}
            fullWidth
            placeholder="please input your golf club"
            id={name}
            name={name}
            variant="outlined"
          />}
      />
      {_renderHelperText()}
    </>
  );
}


export default AutoCompleteBox;
