import React, { useCallback, useState, useContext } from 'react';
import {
  Grid,
  TextField,
  Button,
  Box,
  MenuItem,
} from '@material-ui/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import IFormTeam from 'src/common/interfaces/IFormTeam';
import notificationIcon from 'src/assets/svgs/notificationIcon.svg';
import useStyles from './FormTeam.style';
import { TeamContext } from '../../../TeamContent';
import { createTeam } from 'src/utils/apiUtils';
import { useJWT } from 'src/common/customHooks/useJWT';

const FormTeamForm = () => {
  const classes = useStyles();
  const { getTeamInfo } = useContext(TeamContext);
  const { refreshJwt } = useJWT();
  const [error, setError] = useState(false);
  const history = useHistory();
  const userId = localStorage.getItem('userId');
  const initialValues: IFormTeam = {
    teamName: '',
    grade: ''
  };

  const formTeamSchema = Yup.object().shape({
    teamName: Yup.string().required('Team name is required'),
    grade: Yup.string().required('Team name is required'),
  });

  const handleOnSubmit = async ({ teamName, grade }: IFormTeam) => {
    const teamNames = teamName.trim();
    try {
      const createTeamRes = await createTeam({ userId, teamNames, grade });
      if (createTeamRes.status === 200) {
        await refreshJwt();
        await getTeamInfo();
        history.push("teaminfo")
      }
    } catch (error: any) {
      if (error.response) {
        if (error.response.status === 400) {
          console.log("user already have team!");
        }
        if (error.response.status === 409) {
          setError(error)
        }
      }
      console.log("create team fail");
    }
  };

  const getErrorMsg = useCallback((name: string) => (
    <ErrorMessage name={name}>
      {(msg) => (
        <span className={classes.error}>
          <span className={classes.img}>
            <img src={notificationIcon} alt="notificationIcon" />
          </span>
          <span className={classes.errormessage}>{msg}</span>
        </span>
      )}
    </ErrorMessage>
  ), []);

  const ErrorMsg = () => {
    return (
      <>
        <span className={classes.error}>
          <span className={classes.img}>
            <img src={notificationIcon} alt="notificationIcon" />
          </span>
          <span className={classes.errormessage}>This team name is taken. Try another</span>
        </span>
      </>
    )
  }

  const options = [
    { label: 'A', value: 'A' },
    { label: 'B', value: 'B' },
    { label: 'C', value: 'C' }
  ];

  return (
    <>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center">
        <div className={classes.titlecontainer}>
          <h2>Create a Team</h2>
        </div>
      </Grid>

      <Formik
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        validationSchema={formTeamSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className={classes.textFieldContainer}>
              <div className={classes.formaArea}>
            <div className={classes.teamName}>
              <span className={classes.title}>Team Name</span>
              <span className={classes.subTitle}>Create a team name using letters and numbers</span>
              <Field
                // label="Team Name"
                id="teamName"
                name="teamName"
                variant="outlined"
                // fullWidth
                autoComplete="name"
                as={TextField}
                helpertext={getErrorMsg('teamName')}
              />
              {error && <ErrorMsg />}
            </div>
            <div className={classes.grade}>
              <span className={classes.title}>Your Grade</span>
              <span className={classes.subTitle}>Select A, B or C</span>
              <Field
                select
                name="grade"
                as={TextField}
                // fullWidth
                variant="outlined"
                label="Your Grade"
              >
                {options?.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Field>
              </div>
              </div>
            </div>
            <Box marginTop={3} marginBottom={8} className={classes.btnL}>
              <button
                type="submit"
                // variant="contained"
                // color="primary"
                disabled={isSubmitting}
                className={classes.btn}
              >
                Form a Team
              </button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default FormTeamForm;
