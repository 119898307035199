import React from 'react';
import { TextField, Dialog } from '@material-ui/core';
import useStyles from './InvitationDialog.style';
import Autocomplete from "@material-ui/lab/Autocomplete";
import companyLogo from 'src/assets/pngs/company_logo.png';
import { Formik, Form } from 'formik';
import Chip from '@material-ui/core/Chip';

interface IProps {
  optionalUserList?: any;
  setOpen: Function;
  setErrMsg: Function;
  open: boolean;
  errMsg: IErrMsg;
  handleSubmit: Function;
}
interface IErrMsg {
  show: boolean;
  message: string;
}

const InvitiationDialog = (props: IProps) => {
  const classes = useStyles();
  const { open, optionalUserList, setOpen, handleSubmit, errMsg, setErrMsg } = props;
  const initialValues = {
    invitee: []
  };

  return (
    <>
      <Dialog
        open={open && optionalUserList}
        onClose={() => { setOpen(false) }}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.paper }}
      >
        <div className={classes.box} >
          <img className={classes.img} src={companyLogo} alt="company_logo" />
          <div className={classes.title}>Invite members to join your team</div>
          <Formik
            initialValues={initialValues}
            onSubmit={(props) => {
              if (props.invitee.length === 0) {
                setErrMsg({
                  message: "Select at least 1 user!",
                  show: true
                });
                return;
              }
              handleSubmit(props);
            }}
          >
            {({ setFieldValue }) => (
              <Form className={classes.formBox}>
                <Autocomplete
                  multiple
                  fullWidth
                  id="invitee"
                  options={optionalUserList}
                  autoHighlight
                  getOptionLabel={(option: any) => (option.email + option.firstName + option.lastName)}
                  onBlur={() => {
                    setErrMsg({
                      message: "",
                      show: false
                    });
                  }}
                  onChange={(e, value) => {
                    setFieldValue("invitee", value);
                    setErrMsg({
                      message: "",
                      show: false
                    });
                  }}
                  renderOption={(option) => (
                    <div className={classes.optBox}>
                      <div className={classes.nameBox}>
                        <div>{option.firstName}</div>
                        &nbsp;&nbsp;
                        <div>{option.lastName}</div>
                      </div>
                      <div>{option.email}</div>
                    </div>
                  )}
                  renderTags={(value, getTagProps) => (
                    value.map((option, index) => (
                      <div className={classes.chipBox} key={index}>
                        <Chip variant="outlined" label={option.firstName} {...getTagProps({ index })} />
                      </div>
                    )))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Choose members"
                      placeholder=""
                    />
                  )}
                />
                {errMsg.show &&
                  <div className={classes.errMsg}>{errMsg.message}</div>
                }
                <div className={classes.note}>Note: A Team can only have maximum 2A grades or 2C grades.</div>
                <div className={classes.btnBox}>
                  <div className={classes.btnL}>
                    <button
                      type="submit"
                      className={classes.btn}
                    >
                      INVITE
                    </button>
                  </div>
                  <div className={classes.btnL}>
                    <button
                      type="button"
                      onClick={() => {
                        setOpen(false);
                        setErrMsg({
                          message: "",
                          show: false
                        });
                      }}
                      className={classes.btnDisband}
                    >
                      CANCEL
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Dialog>
    </>
  );
}

export default InvitiationDialog;


