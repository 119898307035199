import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      // marginLeft: 10,
      // paddingLeft: 5,
    },
    ItemIcon: {
      width: '36px',
      height: '36px',
      borderRadius: '25px',
      position: 'relative',
    },
    ItemActiveIcon: {
      backgroundColor: '#511d62',
    },
    Icon: {
      position: 'relative',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -30%)',
      width: '30px',
      height: '35px',
    },
    HomeIcon: {
      position: 'relative',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -70%)',
      width: '30px',
      height: '22px',
    },
    text: {
      fontFamily: 'Poppins-regular',
      fontSize: '18px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.2',
      letterSpacing: 'normal',
      textAlign:'left',
    },
    activeText: {
      color: '#511d62',
    }
  }),
);