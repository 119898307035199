import React from 'react';
import { Card } from '@material-ui/core';
import { useStyles } from './Promotion.style';

const Promotion = () => {
  const title = 'title';
  const classes = useStyles();
  return (
    <div className={classes.promotioncontainer}>
      <div className={classes.singlepromotion}>Promotion</div>
    </div>
  );
};

export default Promotion;
