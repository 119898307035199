import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      borderRadius: 5,
      backgroundColor: '#d4c9da',
      color: '#511d62',
      padding: '25px 35px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    status: {
      fontWeight: 'bold',
    },
    dot: {
      fontSize: 15,
      fontWeight: 'bold',
    }
  }),
);