import React from 'react';
import { Grid } from '@material-ui/core';
import { InputField, CheckboxField, SelectField, AutoCompleteBox } from '../../FormFields';
import { SignalCellular0Bar } from '@material-ui/icons';


const genders = [
  {
    value: 'Female',
    label: 'Female',
  },
  {
    value: 'Male',
    label: 'Male',
  },
  {
    value: 'Other',
    label: 'Other',
  }
];

export default function PersonalInfo(props) {
  const { setFieldValue, state, setState } = props
  const {
    formField: { firstName, lastName, phoneNumber, gender, checkOver18, club }
  } = props;
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputField name={firstName.name} label={firstName.label} fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputField name={lastName.name} label={lastName.label} fullWidth 
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            name={phoneNumber.name}
            label={phoneNumber.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <SelectField
            name={gender.name}
            label={gender.label}
            data={genders}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <AutoCompleteBox
            name={club.name}
            label={club.label}
            setFieldValue={setFieldValue}
            state={state}
            setState={setState}
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxField name={checkOver18.name} label={checkOver18.label}  />
        </Grid>

      </Grid>
    </React.Fragment>
  );
}
