import React from 'react';
import { Container } from '@material-ui/core';
import { useStyles } from './Schedule.style';
import SingleSchedule from './components/SingleSchedule/SingleSchedule';

const Schedule = () => {
  const classes = useStyles();
  return (
    <Container>
      <div className={classes.titlecontainer}>
        <h2>My Schedule</h2>
      </div>
      <div className={classes.schedules}>
        <SingleSchedule />
        <SingleSchedule />
        <SingleSchedule />
        {/* <SingleSchedule />
        <SingleSchedule /> */}
      </div>
    </Container>
  );
};

export default Schedule;
