import { useMediaQuery} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from './AuthLayouts.style';
import RightPanel from 'src/components/RightPanel/RightPanel';
import RightPanelMobile from 'src/components/RightPanelMobile/RightPanelMobile';
import Promotion from 'src/components/RightPanel/components/Promotion/Promotion';
import SideBar from 'src/components/SideBar/SideBar';
import SideBarMobile from 'src/components/SideBarMobile/SideBarMobile';
import Footer from 'src/components/Footer/Footer';

const AuthLayouts = ({children} : any) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className={classes.dashboardcontainer}>
      {matches || <SideBar />}
      <div className={classes.mainpageBody}>
        {matches &&
          <div className={classes.sidebarMobile}>
            <SideBarMobile />
          </div>
        }
        <div className={classes.leftpanel}>
          {children}
          {matches || 
            <div className={classes.promotion}>
            <Promotion />
            <Promotion />
          </div>
          }
        </div>
        {matches ?
          <>
            <div>
              <RightPanelMobile />
            </div>
          </> :
          <RightPanel />
        }      
      </div>
      <Footer />
    </div>
  );
};

export default AuthLayouts;