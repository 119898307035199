import React from 'react';
import promotion from 'src/assets/golfBackground.jpg';
import { useStyles } from './PromotionPanel.style';

const PromotionPanel = () => {
	const classes = useStyles();
	return (
		<div className={classes.promotion}>
			<img src={promotion} alt="promotion" className={classes.image}/>
		</div>
	);
};

export default PromotionPanel;