import React, { useState, useEffect } from 'react';
import * as apiUtils from 'src/utils/apiUtils';

export const useInvoiceDetails = () => {
	//TODO convert current time into trial number
	const trialId = '1';
	const status = 'Payment Due';
	const email = localStorage.getItem('email');
	const userId = localStorage.getItem('userId');
	const firstName = localStorage.getItem('firstName');
	const lastName = localStorage.getItem('lastName');
	const state = localStorage.getItem('state');
	const [isChecked, setIsChecked] = useState(false);
	const [value, setValue] = useState('');
	const [invoice, setInvoice] = useState({
		url: '',
		invoiceNumber: '',
		description: '',
	});
	// const getCurrentTrial = () => {

	// };
	const [open, setOpen] = useState(false);
	const getInvoice = async () => {
		const getInvoiceResponse = await apiUtils.getInvoice(userId, trialId);
		if (getInvoiceResponse.status === 200) {
			const getInvoicePresignResponse = await apiUtils.getInvoicePresign(userId, getInvoiceResponse.data.invoiceNumber);
		  if (getInvoicePresignResponse.status === 200) {
		  	setInvoice({
			  	url: getInvoicePresignResponse.data,
			  	invoiceNumber: getInvoiceResponse.data.invoiceNumber,
			  	description: getInvoiceResponse.data.description,
			  });
				console.log('success');
		  }
		} else {
			console.log('Cannot get invoice');
		}
	};
	useEffect(() => {
		getInvoice();
	}, []);
	const downloadPDF = () => {
		if (firstName && lastName) {
			const firstInitial = firstName.substring(0,1);
			const lastInitial= lastName.substring(0,1);
		}
  	window.open(invoice.url, '_blank');
	};
	const handleChecked = (e: any) => {
		setIsChecked(e.target.checked);
	};
	const handleChange = (e: any) => {
		setValue(e.target.value);
	};
	const handleClose = () => {
		setOpen(false);
	};
	const emailInvoice = async () => {
		const emailInvoiceResponse = await apiUtils.sendInvoiceEmail(userId, invoice.invoiceNumber, email);
		if (value && isChecked) {
			const alterEmailResponse = await apiUtils.sendInvoiceEmail(userId, invoice.invoiceNumber, value);
			if (alterEmailResponse.status === 200) {
				console.log('sent');
				setOpen(true);
			}
		}
		if (emailInvoiceResponse.status === 200) {
			console.log('success');
			setOpen(true);
		}
	};

	return { email, userId, status, invoice, getInvoice, downloadPDF, handleChecked, handleChange, emailInvoice, open, handleClose, };
};
