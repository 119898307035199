import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';
import MaterialLayout from './components/SignupStepBar/components/Layout/MaterialLayout';
import CheckoutPage from './components/SignupStepBar/components/CheckoutPage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down('md')]: {
        marginTop: '100px',
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '150px',
      },
    },
    title: {
      fontFamily: 'Poppins-Bold',
      fontWeight: 'bold',
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        fontSize: '1.25rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '1.6rem',
      },
    },
  }),
);

const SignupContainer: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <Container className={classes.container}>
        <Typography component="h1" variant="h4" align="center" className={classes.title}>
          Sign Up for WACT4BBB
        </Typography>
        <MaterialLayout>
          <CheckoutPage />
        </MaterialLayout>
      </Container>
    </>
  );
};

export default SignupContainer;
