import React, { useState, useEffect,  useLayoutEffect } from 'react';
import { Dialog, IconButton } from '@material-ui/core';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { useStyles } from './RightPanelMobile.style';
import CloseIcon from 'src/assets/pngs/icons/Cross_button.png';
import Calendars from './components/Calendars/Calendars';
import Schedule from './components/Schedule/Schedule';
import PopIcon from 'src/assets/pngs/icons/Pop_Up_Button.png';

const RightPanelMobile = () => {
  const [atBottom, setAtBottom] = useState(false);

  const classes = useStyles(atBottom);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useScrollPosition(({ prevPos, currPos }) => {
    if (-currPos.y + window.innerHeight === window.document.body.clientHeight) {
      setAtBottom(true);
    } else {
      setAtBottom(false);
    }
  })
 
  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <img className={classes.calendarIcon} src={PopIcon} alt="pop icon" />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
      >
        <div className={classes.container}>
          <IconButton className={classes.img} onClick={handleClose}>
            <img  className={classes.icon} src={CloseIcon} alt="close icon" />
          </IconButton>
          <div className={classes.calender}>
            <span className={classes.title}>MY Diary</span>
            <Calendars />
          </div>
          <div className={classes.schedule}>
            <span className={classes.title}>MY Schedule</span>
            <Schedule />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default RightPanelMobile;
