import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "90%",
      height: '250px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
      backgroundColor: '#fff',
      marginRight: 30,
      borderRadius: 20,
      padding: 20,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'row',
        width: "100%",
        height: '150px',
        borderRadius: 10,
      },
    },
    moreIcon: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    avatar: {
      fontSize: 45,
      width: theme.spacing(13),
      height: theme.spacing(13),
      marginBottom: '20px',
      border: '1px double #EF5A24',
      backgroundColor: '#faae40',
      [theme.breakpoints.down('sm')]: {
        width: theme.spacing(11),
        height: theme.spacing(11),
        fontSize: 35,
        margin: '20px 10px',
      },
    },
    inviteIcon: {
      [theme.breakpoints.up('sm')]: {
        height: 104,
        marginTop: 20,
        marginBottom: 20,
        marginLeft: 10,
        marginRight: 10,
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: 20,
        width: 88,
        height: 68,
        marginBottom: 20,
        marginLeft: 10,
        marginRight: 10,
      },
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start',
        marginLeft: 10,
      },
    },
    titleContainer: {
      display: 'flex',
      // height: '48px',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start',
      },
    },
    title: {
      fontSize: 16,
      fontWeight: 500,
      color: '#333'
    },
    subTitle: {
      fontSize: 16,
      fontWeight: 600,
    },
    type: {
      fontSize: 16,
      fontWeight: 600,
      padding: '0px 20px',
      [theme.breakpoints.down('sm')]: {
        padding: '0px 20px 0px 0px',
        width: '185px',

      },
    },
    divider: {
      width: "350px",
      color: '#cacaca',
      marginBottom: 10,
      marginTop: 10,
      [theme.breakpoints.down('sm')]: {
        width: "100%",
      },
    },
    menucaptain: {
      "& .MuiPaper-root": {
        backgroundColor: '#dbd4e1',
        marginTop: 45,
        borderRadius: 10,
      },
    },
    menu: {
      "& .MuiPaper-root": {
        backgroundColor: '#dbd4e1',
        marginTop: 65,
        borderRadius: 10,
      },
    },
    menuItemcaptain: {
      width: '170px',
      whiteSpace: 'normal',
      fontSize: '0.8rem',
      color: '#511d62',
      textAlign: 'center',
      borderBottom: 'solid 1px #511d62',
    },
    menuItem: {
      width: '170px',
      whiteSpace: 'normal',
      fontSize: '0.8rem',
      color: '#511d62',
      textAlign: 'center',
    },
    link: {
      textDecoration: 'none',
    }
  })
);

export default useStyles;