import * as React from 'react';
import { useStyles } from './TournamentBtn.style';
import BackgroundIcon from 'src/assets/golfBackground.jpg';
import BtnIcon from 'src/assets/pngs/icons/click-button.png';

interface ITournamentBtnProps {
  title: string;
  subTitle: string;
  handleSelect: any;
  getTeamInfo: Function;
}

const TournamentBtn = ({ title, subTitle, handleSelect, getTeamInfo }: ITournamentBtnProps) => {
  const classes = useStyles();
  return (
    <>
      <div onClick={() => { handleSelect() }} className={classes.root}>
        <img className={classes.img} src={BackgroundIcon} alt="background image" />
        <div className={classes.container}>
          <div className={classes.textContainer}>
            <span className={classes.title}>{title}</span>
            <span className={classes.subTitle}>{subTitle}</span>
          </div>
          <div onClick={() => { handleSelect(); }} className={classes.btnContainer}>
            <img className={classes.imgBtn} src={BtnIcon} alt="view more" />
          </div>
        </div>
      </div>
    </>
  )
}

export default TournamentBtn;
