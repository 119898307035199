import React, { useState } from 'react';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import {
  Drawer,
  List,
  CssBaseline,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  useMediaQuery,
  Icon,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NavBar from '../NavBar/NavBar';
import { useStyles } from './SideBar.style';
import { useHistory } from 'react-router-dom';
import MenuItem from './components/MenuItem/MenuItem';
import menuItems from './menuItems';
import BackHomeIcon from 'src/assets/pngs/icons/sidebar/backhome.png';
import LogoutIcon from 'src/assets/pngs/icons/sidebar/logout.png';
import LogoHeader from './components/LogoHeader/LogoHeader';
import closeIcon from 'src/assets/pngs/icons/sidebar/closeIcon.png';

const SideBar = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const WpUrl = process.env.REACT_APP_WP_URL;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const persistentDrawer = (path: string) => {
    history.push(path);
    setOpen(true);
  };

  const closeNavigation = () => {
    if (matches) {
      setOpen(false);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    history.push('/login');
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        {open ?
          <>
            <div className={classes.toolbar}>
              <IconButton onClick={handleDrawerClose}>
                <img className={classes.closeIcon} src={closeIcon} alt="close icon" />
              </IconButton>
            </div>
          </> :
          <>
            <div className={classes.toolbar}>
              <IconButton onClick={handleDrawerOpen}>
                <MenuIcon />
              </IconButton>
            </div>
          </>
        }
        <LogoHeader />
        <List className={classes.menu}>
          {menuItems.map((item) => (
            <MenuItem
              key={item.path}
              label={item.label}
              path={item.path}
              icon={item.icon}
              activeIcon={item.activeIcon}
              onClick={closeNavigation}
            />
          ))}
        </List>
        <Divider className={classes.divider} />
        <MenuItem
          label={'Back to Home'}
          path={WpUrl}
          icon={BackHomeIcon}
          activeIcon={BackHomeIcon}
          onClick={closeNavigation}
        />
        <div className={classes.btnlist}>
          {open ?
            <>
              <button onClick={handleLogout} className={classes.btn}>Log Out</button>
            </> :
            <>
              <ListItem
                button
                onClick={handleLogout}
              >
                <ListItemIcon>
                  <Icon className={classes.ItemIcon}>
                    <img
                      className={classes.Icon}
                      src={LogoutIcon}
                      alt="log out"
                    />
                  </Icon>
                </ListItemIcon>
              </ListItem>
            </>
          }
        </div>
      </Drawer>
    </div >
  );
}

export default SideBar;
