import React from 'react';
import UserProfileIcon from 'src/components/UserProfileIcon/UserProfileIcon';
import EditProfilePanel from './components/EditProfilePanel/EditProfilePanel';
import GolfStatusPanel from './components/GolfStatusPanel/GolfStatusPanel';
import PromotionPanel from './components/PromotionPanel/PromotionPanel';
import { useMemberProfile } from './hooks';
import { useStyles } from './ProfilePage.style';

const ProfilePage = () => {
  const classes = useStyles();
  const { memberProfile } = useMemberProfile();

  return (
    <>
      <div className={classes.leftpanel}>
        <div className={classes.titlepanel}>
          <UserProfileIcon name={memberProfile.firstName} size="42" textSizeRatio={2} />
          <h1 className={classes.title}>MY PROFILE</h1>
          <h3>Welcome, {memberProfile.firstName}!</h3>
        </div>
        <EditProfilePanel member={memberProfile} />
        <GolfStatusPanel member={memberProfile} />
        <p className={classes.info}>If you wish to change your handicap or update your grade, please contact info@wact4bbb.com</p>
        <PromotionPanel />
      </div>
    </>
  );
};

export default ProfilePage;
