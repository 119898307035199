import React from 'react';
import { useStyles } from './Notify.style';
import InviteIcon from 'src/assets/pngs/icons/invite-icon.png';
import { acceptInvitation, rejectInvitation } from 'src/utils/apiUtils';
import { useJWT } from 'src/common/customHooks/useJWT';

interface IProps {
  id: string;
  teamId: string;
  inviterId: string;
  inviteeId: string;
  teamName: string;
  inviterFirstName: string;
  inviteeFirstName: string;
  getInvitationNotificationByUser: Function;
  setUpdate: Function;
  setDialogInfo: Function;
  setOpen: Function;
}

const Notify = (props: IProps) => {
  const { setDialogInfo, setUpdate, inviterFirstName, teamName, setOpen,
    teamId, inviterId, inviteeId, id, getInvitationNotificationByUser } = props
  const classes = useStyles();
  const { refreshJwt } = useJWT();

  console.log(inviteeId, teamId, id);
  const handleAccept = async () => {
    try {
      const acceptResponse = await acceptInvitation(inviteeId, teamId, id);
      if (acceptResponse.status === 200) {
        console.log("accept invitation successfull!");
        refreshJwt();
        setDialogInfo(teamName);
        setOpen(true);
      }
    } catch (error: any) {
      if (error?.response.status !== 200) {
        console.log("accept invitation fail!");
      }
    }
  }

  const handleReject = async () => {
    try {
      const rejectResponse = await rejectInvitation(inviteeId, teamId, id);
      if (rejectResponse.status === 200) {
        console.log("reject invitation successfull!")
        getInvitationNotificationByUser();
      }
    } catch (error: any) {
      if (error?.response.status !== 200) {
        console.log("reject invitation fail!")
      }
    }
  }
  return (
    <>
      <div className={classes.root}>
        <img className={classes.img} src={InviteIcon} alt="invite icon" />
        <div className={classes.container}>
          <div className={classes.textContainer}>
            <span className={classes.title}>Team Invitation</span>
            <span className={classes.msg}>{inviterFirstName} invited you to join {teamName}</span>
          </div>
          <div className={classes.btnContainer}>
            <button className={classes.acpBtn} onClick={handleAccept}>Accept</button>
            <button className={classes.rejBtn} onClick={handleReject}>Reject</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Notify;
