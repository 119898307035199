import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '60vw',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& h1': {
        marginBottom: 40,
      }
    },
    container: {
      width: '70%',
      backgroundColor: '#d4c9da',
      borderRadius: 10,
      color: '#511d62',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 40,
    },
    cart: {
      backgroundColor: '#511d62',
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      fontSize: 20,
      fontWeight: 'bold',
      padding: 40,
    },
    carticon: {
      marginRight: 10,
    },
    ticket: {
      marginTop: 150,
      marginLeft: 40,
      position: 'absolute',
      width: '60px',
    },
    title: {
      color: 'black',
    },
    price: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '40px 120px 0 120px',
      fontSize: 20,
      fontWeight: 'bolder',
    },
    actualprice: {
      color: 'red',
    },
    discount: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '20px 120px 30px 120px',
      fontSize: 18,
    },
    discountprice: {
      textDecoration: 'line-through',
      color: 'black',
    },
    gst: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '20px 120px 0 120px',
      fontSize: 18,
    },
    total: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '40px 120px 20px 120px',
      fontSize: 18,
      fontWeight: 'bold',
    },
    btn: {
      width: '15%',
      alignSelf: 'center',
      fontSize: 15,
      fontWeight: 600,
      backgroundColor: '#f8af41',
      border: 'none',
      borderRadius: 5,
      color: '#fff',
      padding: '10px 20px',
      cursor: 'pointer',
      transitionDuration: '0.4s',
      "&:hover": {
        backgroundColor: '#ef7d0f',
      },
    },
  })
);

export default useStyles;