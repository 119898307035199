import React from 'react';
import { Link } from 'react-router-dom';
import {
    Icon,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@material-ui/core";
import logo from 'src/assets/pngs/site-logo.png';
import { useStyles } from './LogoHeader.style';

const LogoHeader = () => {
  const classes = useStyles();
  const WpUrl = process.env.REACT_APP_WP_URL;

  return (
    <>
      <a href={WpUrl} className={classes.link}>
        <div>
          <span>
           <img src={logo} alt="dashboard-logo" className={classes.img} />
          </span>
        </div>
      
        <div className={classes.textPosition}>
          <span className={classes.text}>WACT4BBB</span>
          <span className={classes.text}>Tournament</span>
        </div>
      </a>
    </>
  )
}

export default LogoHeader;