import React from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import { TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
const useStyles = makeStyles((theme:Theme) => ({
  label:{
    marginBottom:'15px',
  },
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#511d62"
    },
  }
}));


const InputField = (props:any) => {
  const classes = useStyles();
  const { errorText,label, disable, ...rest } = props;
  const [field, meta] = useField(props);

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }
  }

  return (
    <div>
      <div className={classes.label}>{label}</div>
      <TextField
        className={classes.root}
        disabled={disable}
        variant="outlined"
        type="text"
        error={meta.touched && meta.error && true}
        helperText={_renderHelperText()}
        
        {...field}
        {...rest}
      />
    </div>
  );
};

export default InputField;
