import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
createStyles({
    dashboardcontainer: {
      width: '100%',
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
    },
    mainpageBody: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      fontFamily: 'Poppins-regular',
      marginBottom: 76,
      backgroundColor: '#f3f2f7',
      justifyContent: 'center',
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    promotion: {
      display: 'flex',
      marginTop: '80px',
      justifyContent: 'space-around',

    },
    leftpanel: {
      width: '100%',
      paddingTop: '62px',
      // maxWidth: '1035px',
      display: 'flex',
      // paddingLeft: '30px',
      // border: 'solid 2px red',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        padding: '70px 40px',
      },
      // width: 'calc(100% - 426px)',
      marginRight: '426px',
    },
    leftTop: {
      height: '20vh',
    },
    leftBottom: {
      height: '20vh',
    },
    rightpanel: {
      paddingLeft: '30px',
      paddingRight: '45px',
      paddingTop: '62px',
      display: 'flex',
      backgroundColor: '#eae7ee',
      flexDirection: 'column',
    },
    sidebarMobile: {
      position: 'absolute',
      top: 20,
      left: 20,
    }
  }),
);
