import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { InputField, SelectField, AutoCompleteBox } from '../FormField/index';
import { useStyles } from './MainColumn.style'

const states = [
  {
    value: 'VIC',
    label: 'Victoria'
  },
  {
    value: 'NSW',
    label: 'New South Wales'
  },
  {
    value: 'QLD',
    label: 'Queensland'
  },
  {
    value: 'WA',
    label: 'Western Australia'
  },
  {
    value: 'SA',
    label: 'South Australia'
  },
  {
    value: 'NT',
    label: 'Northern Territory'
  },
  {
    value: 'ACT',
    label: 'Australian Capital Territory'
  },
  {
    value: 'TAS',
    label: 'Tasmania'
  }
];

const genders = [
  {
    value: 'Female',
    label: 'Female',
  },
  {
    value: 'Male',
    label: 'Male',
  },
  {
    value: 'Other',
    label: 'Other',
  }
];

const MainColumn = (props: any) => {
  const { hasTeam, setFieldValue, club, initClub } = props;
  // const hasTeam = true; //mock value TODO replace "hasTeam"
  const classes = useStyles();
  return (

    <Grid className={classes.columnBox} container spacing={3}>
      <Grid item xs={12} sm={6}>
        <InputField name='firstName' label='First Name' fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name='lastName' label='Last Name' fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField
          name="gender"
          label="gender"
          data={genders}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} >
        <InputField name='email' label='Email' disable fullWidth />
      </Grid>
      <Grid item xs={12} sm={6} >
        <InputField name='phoneNumber' label='Phone Number' fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField
          name="state"
          label="State, Province, or Region"
          data={states}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} >
        <InputField name='postCode' label='PostalCode' fullWidth />
      </Grid>
      <Grid item xs={12} sm={6} >
        <InputField name='country' label='Country' disable fullWidth />
      </Grid>
      <Grid item xs={12} sm={6} >
        <AutoCompleteBox hasTeam={hasTeam} initClub={initClub} club={club} setFieldValue={setFieldValue} name='club' label='Club' disable fullWidth />
      </Grid>
    </Grid>
  )
};

export default MainColumn;



