import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      // display: 'flex',
      // flexDirection: 'column',
      // alignItems: 'center',
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: 20,
    },
    title: {
      color: '#333',
      fontWeight: 600,
      letterSpacing: 6.8,
      fontSize: '35px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '24px',
      },
    },
    subTitle: {},
    subContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
      },
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    teamContainer: {
      width: '85%',
      height: '100px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 15,
      backgroundColor: '#dbd4e1',
      [theme.breakpoints.down('xs')]: {
        width: '75%',
        height: '60px',
        marginRight: 0,
      },
      marginRight: 30,
    },
    label: {
      fontSize: 22,
      fontWeight: 600,
      color: '#511d62',
      marginRight: 10,
      [theme.breakpoints.down('xs')]: {
        fontSize: 17,
      },
    },
    teamName: {
      fontSize: 22,
      color: '#511d62',
      marginLeft: 10,
      [theme.breakpoints.down('xs')]: {
        fontSize: 17,
      },
    },
    btnContainer: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'row',
        justifyContent: 'center',
      },
    },
    btnMore: {
      margin: 10,
      fontSize: 13,
      fontWeight: 600,
      backgroundColor: '#f8af41',
      border: 'none',
      borderRadius: 5,
      color: '#fff',
      padding: '10px 20px',
      cursor: 'pointer',
      transitionDuration: '0.4s',
      "&:hover": {
        backgroundColor: '#ef7d0f',
      },
      [theme.breakpoints.down('xs')]: {
        width: '110px'
      },
    },
    btnDisband: {
      margin: 10,
      fontSize: 13,
      fontWeight: 600,
      backgroundColor: '#333333',
      border: 'none',
      borderRadius: 5,
      color: '#fff',
      padding: '10px 20px',
      cursor: 'pointer',
      transitionDuration: '0.4s',
      "&:hover": {
        backgroundColor: '#1f1f1f',
      },
      [theme.breakpoints.down('xs')]: {
        width: '110px'
      },
    },
  }));
