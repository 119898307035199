import React from 'react';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { useStyles } from './Notifications.style';
import SingleNotification from './components/SingleNotification/SingleNotification';

const Notifications = () => {
  const classes = useStyles();
  return (
    <div className={classes.notificationContainer}>
      <div className={classes.titleContainer}>
        {/*<NotificationsIcon color="primary" className={classes.icon} />*/}
        <span className={classes.title}>Notifications</span>
      </div>
      <div className={classes.notifications}>
        <SingleNotification />
        <SingleNotification />
        <SingleNotification />
        <SingleNotification />
      </div>
    </div>
  );
};

export default Notifications;
