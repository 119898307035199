import React, { useEffect, useState } from 'react';
import {
    Icon,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@material-ui/core";
import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";
import { useStyles } from './MenuItem.style';

const MenuItem = ({label, path, icon, activeIcon, onClick} : any) => {
  const [active, setActive] = useState(false);
  const location = useLocation();
  const classes = useStyles();
  const [home, setHome] = useState(false);
  
  useEffect(() => {
    if(label === 'Back to Home') {
      setHome(true);
    }
    setActive(location.pathname === path);
  }, [location, path]);
  
  return (
    <>
      {home ? 
      <>
        <ListItem
          button
          component='a'
          href={path}
          className={classes.menuItem}
          onClick={onClick}
        >
          <ListItemIcon>
            <Icon className={classes.ItemIcon}>
              <img
                className={classes.HomeIcon}
                src={icon}
                alt={label}
              />
            </Icon>
          </ListItemIcon>
          <ListItemText
            primary={label}
            className={classes.text}
          />
        </ListItem>
      </> : 
      <>
        <ListItem
        button
        component={Link}
        to={path}
        className={classes.menuItem}
        onClick={onClick}
        >
        <ListItemIcon>
            <Icon className={clsx(classes.ItemIcon, active && classes.ItemActiveIcon)}>
            <img
                className={classes.Icon}
                src={active ? activeIcon : icon}
                alt={label}
            />
            </Icon>
        </ListItemIcon>
        <ListItemText
            primary={label}
            className={clsx(classes.text, active && classes.activeText)}
        />
        </ListItem>
    </>
    }
    </>
  )
}

export default MenuItem;