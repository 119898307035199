import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
createStyles({
    root: {
      boxSizing: 'border-box',
      fontFamily: 'Poppins-regular',
      backgroundColor: '#fff',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      width: 480,
      height: 350,
      paddingLeft: 30,
      paddingRight: 30,
    },
    img: {
      width: 50,
      height: 50,
    },
    textContainer: {
      marginBottom: 20,
    },
    title: {
      fontSize: 17,
      fontWeight: 600,
    },
    subTitle: {
      fontSize: 24,
      fontWeight: 600,
      marginBottom: 15,
    },
    content: {
      fontSize: 17,
    },
    btnContainer: {
      cursor: 'pointer',
    },
    btn: {
      width: 150,
      height: 50,
    },
}),
);
