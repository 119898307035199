import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';

const drawerWidth = 240;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    menu: {
      marginTop: 15,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(8) + 3,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(8) + 3,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: 0,
    },
    icon: {
      width: 50,
      height: 50,
    },
    text: {
      color: 'primary',
      marginLeft: 20,
      fontSize: 18,
    },
    divider: {
      marginBottom: 20,
    },
    btnlist: {
      marginTop: 15,
      textAlign: 'center',
    },
    btn: {
      border: 'none',
      width: '120px',
      height: '40px',
      borderRadius: '10px',
      backgroundColor: '#fcb040',
      fontFamily: 'Poppins-regular',
      fontSize: '15px',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.2',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#fff',
      cursor: 'pointer',
    },
    ItemIcon: {
      width: '36px',
      height: '36px',
      borderRadius: '25px',
      position: 'relative',
    },
    Icon: {
      position: 'relative',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -70%)',
      width: '24px',
      height: '20px',
    },
    closeIcon: {
      width: '30px',
      height: '30px',
    },
  }),
);
