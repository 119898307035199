import React from 'react';
import { useStyles } from './RightPanel.style';
import Calendars from './components/Calendars/Calendars';
import Schedule from './components/Schedule/Schedule';
import Promotion from './components/Promotion/Promotion';

const RightPanel = () => {
	const classes = useStyles();
	return (
		<div className={classes.rightpanel}>
      <Calendars />
      <Schedule />
      <div className={classes.promotion}>
        <Promotion />
      </div>
    </div>
	);
};

export default RightPanel;
