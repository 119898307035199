import React, { useState, useContext } from 'react';
import InviteIcon from 'src/assets/pngs/icons/Invite_icon.png';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Avatar, IconButton, Divider } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import useStyles from './TeamMemberCard.style';
import { TeamContext } from 'src/pages/TeamPage/TeamContent';
import { inviteToTeam } from 'src/utils/apiUtils';
import InvitiationDialog from './InvitationDialog/InvitiationDialog';

interface IProps {
  optionalUserList?: any;
  teamId?: number | null;
  memberId: number | null;
  remainingAmount?: number;
  memberFirstName: string;
  memberLastName: string;
  golfLevel: string;
  membertype: string;
  fetchUserOptionList: Function;
}

const TeamMemberCard = (props: IProps) => {
  const classes = useStyles();
  const userId = localStorage.getItem('userId');
  const { getPendingInvitationList } = useContext(TeamContext);
  const { fetchUserOptionList, optionalUserList, teamId, memberId, memberFirstName, memberLastName, membertype, golfLevel, remainingAmount } = props;
  const avatarName = memberFirstName.substring(0, 1).toUpperCase() + memberLastName.substring(0, 1).toUpperCase();
  let type = 'TEAM MEMBER';
  switch (membertype) {
    case 'NominatedCaptain':
      type = 'NOMINATED CAPTAIN';
      break;
    case 'Captain':
      type = 'CAPTAIN';
      break;
    case 'Member':
      type = 'TEAM MEMBER';
      break;
  }
  const [open, setOpen] = useState(false);
  const [errMsg, setErrMsg] = useState({
    show: false,
    message: ""
  });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [isCaptain, setIsCaptain] = useState(false);

  const handleCaptain = () => {
    setIsCaptain(true);
  };

  const handleMember = () => {
    setIsCaptain(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    console.log(userId);
    console.log(memberId);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = async ({ invitee }: any) => {
    if (remainingAmount && remainingAmount < invitee.length) {
      setErrMsg({
        message: `can not sent more than ${remainingAmount} invitation`,
        show: true
      });
      return;
    }
    let inviteeList = invitee.map((obj: any) => { return obj.id });
    try {
      const invititationRes = await inviteToTeam({ teamId, userId, inviteeList });
      if (invititationRes.status === 200) {
        console.log('send successfully');
        getPendingInvitationList();
        setOpen(false);
      }
    } catch (error: any) {
      if (error.response) {
        if (error.response.status === 409) {
          setErrMsg({
            message: "the user already been invited in this team",
            show: true
          });
          console.log('the user already been invited in this team');
        }
      }
    }
  }

  return (
    <>
      <div key={memberId} className={classes.root}>
        {memberId === null ?
          <>
            <div onClick={() => { setOpen(true); fetchUserOptionList(); }}>
              <img className={classes.inviteIcon} src={InviteIcon} alt="invite icon" />
            </div>
            <div className={classes.textContainer}>
              <div className={classes.titleContainer}>
                <span className={classes.subTitle}>Invite Member</span>
              </div>
              <Divider className={classes.divider} />
              <div className={classes.type}>
                {type}
              </div>
            </div>
          </> :
          <>
            { type === 'TEAM MEMBER' ?
            <>
              { memberId.toString() === userId ?
                <IconButton className={classes.moreIcon} onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton> : null
              }
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.menucaptain}
              >
                <MenuItem className={classes.menuItem}>
                  <Link to="/auth/payment/payment-member" onClick={handleCaptain} className={classes.link}>
                    Pay for the Captain and Team Qualifying Round
                  </Link>
                </MenuItem>
              </Menu> 
            </> :
            <>
              { memberId.toString() === userId ?
                <IconButton className={classes.moreIcon} onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton> : null
              }
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.menucaptain}
              >
                <MenuItem className={classes.menuItemcaptain} onClick={handleClose}>Nominate another captain</MenuItem>
                <MenuItem className={classes.menuItem}>
                  <Link to="/auth/payment/payment-captain" onClick={handleCaptain} className={classes.link}>
                    Pay for the Captain Qualifying Round
                  </Link>
                </MenuItem>
              </Menu>
            </>
            }
            <div><Avatar className={classes.avatar}>{avatarName}</Avatar></div>
            <div className={classes.textContainer}>
              <div className={classes.titleContainer}>
                <span className={classes.title}>{memberFirstName} {memberLastName}</span>
                <span className={classes.subTitle}>Grade {golfLevel}</span>
              </div>
              <Divider className={classes.divider} />
              <div className={classes.type}>
                {type}
              </div>
            </div>
          </>
        }
      </div>
      <InvitiationDialog setErrMsg={setErrMsg} errMsg={errMsg} handleSubmit={handleSubmit} open={open} setOpen={setOpen} optionalUserList={optionalUserList} />
    </>
  );
}

export default TeamMemberCard;
