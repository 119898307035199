import React, { useState } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  CircularProgress
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as apiUtils from 'src/utils/apiUtils';
import PersonalInfo from './Forms/PersonalInfo';
import PersonalDetails from './Forms/PersonalDetails';
import LoginInfo from './Forms/LoginInfo';
import { useHistory } from 'react-router-dom';
import SignUpSuccess from './SignUpSuccess';
import WarningMessage from 'src/components/Login/components/WarningMessage/WarningMessage';
import validationSchema from './FormModel/validationSchema';
import checkoutFormModel from './FormModel/checkoutFormModel';
import formInitialValues from './FormModel/formInitialValues';
import useStyles from './styles';

const steps = ['Personal information', 'Personal details', 'login information'];
const { formId, formField } = checkoutFormModel;

function _renderStepContent(step, setFieldValue, state, setState) {
  switch (step) {
    case 0:
      return <PersonalInfo setFieldValue={setFieldValue} formField={formField} state={state} setState={setState} />;
    case 1:
      return <PersonalDetails formField={formField} />;
    case 2:
      return <LoginInfo formField={formField} />;
    default:
      return <div>Not Found</div>;
  }
}

export default function CheckoutPage() {
  const [state, setState] = useState('');
  const [error, setError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const history = useHistory();

  async function _submitForm(values, actions) {
    const {
      firstName,
      lastName,
      gender,
      phoneNumber,
      postCode,
      country,
      club,
      state,
      handiCap,
      golfLinkNumber,
      email,
      password,
      checkReceive } = values
    try {
      const signupResponse = await apiUtils.signup({
        firstName,
        lastName,
        gender,
        phoneNumber,
        postCode,
        country,
        club,
        state,
        handiCap,
        golfLinkNumber,
        email,
        password,
        checkReceive
      });
      if (signupResponse.status === 200) {
        localStorage.setItem('email', email);
        setActiveStep(activeStep + 1);
        setError(false);
        setEmailError(false);
        history.push('/activation-email');
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status = 409) {
          setActiveStep(2);
          setEmailError(true);
        }
        if (error.response.status >= 500) {
          setActiveStep(2);
          setError(true);
        }
      }
    }
    actions.setSubmitting(false);
  }

  function _handleSubmit(values, actions) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  return (
    <React.Fragment>
      {error && <WarningMessage content="Oops! something went wrong" />}
      {emailError && <WarningMessage content="Email already exists" />}
      <Stepper activeStep={activeStep} className={classes.stepper} alternativeLabel>
        {steps.map(label => (
          <Step className={classes.stepColor} key={label}>
            <StepLabel >{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <React.Fragment>
        {activeStep === steps.length ? (
          <SignUpSuccess />
        ) : (
          <Formik
            initialValues={formInitialValues}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form id={formId}>
                {_renderStepContent(activeStep, setFieldValue, state, setState)}

                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={_handleBack} className={classes.button}>
                      Previous
                    </Button>
                  )}
                  <div className={classes.wrapper}>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                        className={classes.button}
                    >
                      {isLastStep ? 'Submit' : 'Next'}
                    </Button>
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </React.Fragment>
    </React.Fragment>
  );
}
