import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import img from 'src/assets/pngs/password_email_page_caption.png';
import useStyles from './PasswordEmailPage.style';

const PasswordEmailPage = () => {
  const classes = useStyles();
  return (
    <>
      <Grid container direction="column" className={classes.grid}>
        <Grid item>
          <Typography align="center" className={classes.title}>
            Reset Password for WACT4BBB
          </Typography>
        </Grid>
        <Grid item className={classes.img_grid}>
          <img src={img} alt="welcome" className={classes.img} />
        </Grid>
        <Grid item>
          <Typography align="center" className={classes.subtitle}>Check your email</Typography>
          <Typography align="center" >A reset password link has sent to your email address.</Typography>
        </Grid>
      </Grid>
    </>
  );
};
export default PasswordEmailPage;