import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => createStyles({
  error: {
    display: 'block',
  },
  errormessage: {
    fontFamily: 'Poppins-SemiBold',
    color: '#eb5757',
    fontWeight: 600,
    fontSize: '0.875rem',
    marginLeft: '8px',
    display: 'inline',
  },
  send_button: {
    textTransform: 'initial',
    marginTop: '2rem',
  },
  label: {
    fontSize: '16px',
    color: '#828282',
  },
  notification_img: {
    display: 'inline',
    float: 'left',
  },
  back_button: {
    marginTop: '4rem',
    right: '11px',
  },
  warning: {
    margin: '20px 0',
    padding: '3%',
    borderRadius: '10px',
    border: 'solid 2px #eb5757',
    backgroundColor: '#fcebea',
    display: 'flex',
    alignItems: 'center',
  },
  warning_message: {
    fontWeight: 500,
    marginLeft: '16px',
    textAlign: 'left',
    color: '#eb5757',
  },
}));
