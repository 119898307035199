import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      marginTop: 12,
      width: 40,
      height: 40,
    },
    titleContainer: {
      display: 'flex',
    },
    title: {
      fontFamily: 'Poppins-regular',
      fontSize: '20px',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.2',
      letterSpacing: 'normal',
      textAlign: 'left',
      color: '#333',
      marginTop: '20px',
      marginBlock: '20px',
    },
    notificationContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    notifications: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      // flexDirection: 'column',
    }
  }),
);
