import axios from 'axios';

const baseUrl = process.env.REACT_APP_WACT4_API;

// Start getNewJwt
export const getNewJwt = () => axios({
  method: 'get',
  url: `${baseUrl}/refresh`,
  headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
});

export default getNewJwt;
// End getNewJwt
