import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
    title: {
        maxWidth: '700px',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        [theme.breakpoints.down('md')]: {
            maxWidth: '600px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '95%',
            fontSize: 15,
        },
    },
    subTitle: {
        maxWidth: '700px',
        fontSize: '1.2rem',
        fontWeight: 'bold',
        [theme.breakpoints.down('md')]: {
            maxWidth: '600px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            fontSize: 13,
        },
    },
    selectBg: {
    '&:hover': {
        backgroundColor: '#E6DDE7',
      },
    
    }
}));

export default useStyles;