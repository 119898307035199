import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    profilePageBody: {
      display: 'flex',
      flexDirection: 'column',
      // border: 'solid 2px black',
      marginTop: 50,
      marginBottom: 100,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
      paddingLeft:'80px',
      paddingRight:'80px',
      
    },
    profilePage: {
      backgroundColor: '#f3f2f7',
      minHeight:'100vh',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 76,
      justifyContent: 'space-between',
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    titleLine: {
      display: 'flex',
      flexDirection: 'row',
      width:'100%',
      justifyContent: 'center',
      fontSize: '34px',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.2',
      letterSpacing: '6.8px',
      textAlign: 'center',
      color: '#333',
      marginBottom: '35.2px',
    },

    maincontainer: {
      width:'100%',
      display: 'flex',
    },

    button:{
      display:'flex',
      margin:'0 auto',
      color: '#fff',
      width:'120px',
      height: '40px',
      fontSize: '15px',
      fontWeight: 600,
      lineHeight: 1.2,
      borderRadius: '10px',
      backgroundColor: '#fcb040',
      marginTop:'30px',
      marginBottom:'20px',
      '&:hover': {
        backgroundColor: '#ef7d0f',
        color: '#fff',
      },
    },
  }),
);
