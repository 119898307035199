import React from 'react';
import { Dialog } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useHistory } from 'react-router-dom';

interface IProps {
  open: boolean;
  setOpen: Function;
  dialogInfo: string;
  setDialogInfo: Function;
  freezeTime: number;
}
const NotificationDialog = (props: IProps) => {
  const { open, setOpen, dialogInfo, freezeTime } = props;
  const history = useHistory();
  return (
    <Dialog
      open={open}
    >
      <DialogTitle id="alert-dialog-title">Congratulations! Accepted the invitation successfully !</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You have successfully joined the {dialogInfo}.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={freezeTime >= 0}
          onClick={() => {
            setOpen(false);
            history.push('/auth/team/teamselect');
          }}
          color="primary">
          Go to Your Team &nbsp; {freezeTime >= 0 && <span>({freezeTime})</span>}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NotificationDialog;
