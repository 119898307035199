import React from 'react';
import IUserProfile from 'src/common/interfaces/IUserProfile';
import { useStyles } from './GolfStatusPanel.style';

const GolfStatusPanel = ({ member }: IUserProfile) => {
	const classes = useStyles();
	const userId = localStorage.getItem('userId');
	const { firstName, lastName, email, phoneNumber, state, postcode, golfLinkNumber, handicap, grade, clubId, hasTeam, gender } = member;

	return (
		<div className={classes.container}>
			<span className={classes.status}>Golf Link Number: {golfLinkNumber}</span>
			<span className={classes.dot}>&#183;</span>
			<span className={classes.status}>Handicap: {handicap}</span>
			<span className={classes.dot}>&#183;</span>
			<span className={classes.status}>Grade: {grade}A</span>
		</div>
	);
};

export default GolfStatusPanel;