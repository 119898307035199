import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthLayouts from './components/AuthLayouts/AuthLayouts';
import DashboardPage from 'src/pages/DashboardPage/DashboardPage';
import ProfilePage from 'src/pages/ProfilePage/ProfilePage';
import TeamLayout from './components/teamLayout/TeamLayout';
import PaymentLayouts from './components/PaymentLayouts/PaymentLayouts';
import ProfileEditPage from 'src/pages/EditProfilePage/ProfileEditPage';

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  return (
    <>
      <Route
        {...rest}
        render={
          (props) => (
            localStorage.getItem('accessToken')
              ? <Component {...props} />
              : <Redirect to={{ pathname: '/login' }} />
          )
        }
      />
    </>
  )
};

const Auth = () => {
  return (
    <AuthLayouts>
      <PrivateRoute exact path="/auth/dashboard" component={DashboardPage} />
      <PrivateRoute path="/auth/team" component={TeamLayout} />
      <PrivateRoute path="/auth/profile" component={ProfilePage} />
      <PrivateRoute path="/auth/edit-profile" component={ProfileEditPage} />
      <PrivateRoute path="/auth/payment" component={PaymentLayouts} />
    </AuthLayouts>
  );
};
export default Auth;
