import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    schedulecontainer: {
      lineHeight: '1.2',
      letterSpacing: 'normal',
      textAlign: 'left',
      color: '#511d62',
      backgroundColor: '#eae7ee',
      marginTop: 20,
      maxWidth: '500px',
      display: 'flex',
      borderRadius: 20,
      padding: 10,
      [theme.breakpoints.down('xs')]: {
        maxWidth: '350px',
        padding: 5,
      }
    },
    info: {
      marginLeft: 15,
      marginTop: 12,
      marginBottom: 12,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    link: {
      color: 'black',
      fontSize: 12,
    },
    title: {
      fontSize: '15px',
      fontWeight: 600,
      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
      }
    },
    details: {
      fontSize: '15px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
      }
    },
    iconPosition: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 15,
      paddingRight: 5,
      [theme.breakpoints.down('xs')]: {
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 10,
        paddingRight: 5,
        }
    },
    icon: {
      color: '#999999',
      width: '60px',
      height: '41px',
      [theme.breakpoints.down('xs')]: {
        width: '45px',
        height: '35px',
      }
    },
  }),
);
