import React, { useState } from 'react';
import { Container } from '@material-ui/core';
import NominatedCaptainPayment from './components/NominatedCaptainPayment/NominatedCaptainPayment';
import TeamMemberPayment from './components/TeamMemberPayment/TeamMemberPayment';
import useStyles from './PaymentPage.style';

const PaymentPage = () => {
	const classes = useStyles();
	const [isCaptain, setIsCaptain] = useState(false);
	return (
		<div className={classes.root}>
			<h1>My Payment</h1>
			<Container className={classes.container}>
				{ isCaptain === false ?
					<TeamMemberPayment />
					:
					<NominatedCaptainPayment />
				}
			</Container>
		</div>
	);
};

export default PaymentPage;