import React, { useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { Link, useHistory } from 'react-router-dom';
import IUserProfile from 'src/common/interfaces/IUserProfile';
import { useStyles } from './EditProfilePanel.style';

const EditProfilePanel = ({ member }: IUserProfile) => {
  const classes = useStyles();
  const history = useHistory();
  const [user, setUserProfile] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    state: '',
    postcode: '',
    golfLinkNumber: '',
    handicap: '',
    grade: '',
    clubId: '',
    hasTeam: false,
    gender: '',
  });
  const { firstName, lastName, email, phoneNumber, state, postcode, golfLinkNumber, handicap, grade, clubName, hasTeam, gender } = member;
  localStorage.setItem('state', state);
  return (
    <div className={classes.container}>
      <div className={classes.details}>
        <div className={classes.titlebanner}>
          <h3>My Details</h3>
          <Link to={{
            pathname: '/auth/edit-profile',
            state: { user: member },
          }}>
            <EditIcon className={classes.icon} />
          </Link>
        </div>
        <div className={classes.detailbanner}>
          <h4>Name</h4>
          <div>{firstName} {lastName}</div>
          <h4>Email</h4>
          <div>{email}</div>
          <h4>Mobile Number</h4>
          <div>{phoneNumber}</div>
        </div>
      </div>
      <div className={classes.details}>
        <div className={classes.titlebanner}>
          <h3>Location</h3>
          <Link to={{
            pathname: '/auth/edit-profile',
            state: { user: member },
          }}>
            <EditIcon className={classes.icon} />
          </Link>
        </div>
        <div className={classes.detailbanner}>
          <h4>Postcode</h4>
          <div>{postcode}</div>
          <h4>State</h4>
          <div>{state}</div>
          <h4>Country</h4>
          <div>Australia</div>
        </div>
      </div>
      <div className={classes.details}>
        <div className={classes.titlebanner}>
          <h3>Golf Club</h3>
          <Link to={{
            pathname: '/auth/edit-profile',
            state: { user: member },
          }}>
            <EditIcon className={classes.icon} />
          </Link>
        </div>
        <div className={classes.detailbanner}>
          <div className={classes.club}>{clubName}</div>
        </div>
      </div>
    </div>
  );
};

export default EditProfilePanel;
