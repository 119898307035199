import React from 'react';
import { useStyles } from './Schedule.style';
import SingleSchedule from './components/SingleSchedule/SingleSchedule';

const Schedule = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.schedules}>
        <SingleSchedule />
        <SingleSchedule />
      </div>
    </>
  );
};

export default Schedule;
