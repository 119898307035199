import React, { useState } from 'react';
import Calendar from 'react-calendar';
import './Calendar.css';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { useStyles } from './Calendars.style';

const Calendars = () => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.titlecontainer}>
        {/* <DateRangeIcon color="primary" className={classes.icon} /> */}
        <h2>My Diary</h2>
      </div>
      <div className={classes.datefield}>
        <Calendar
          className={classes.calendar}
        />
      </div>
    </div>
  );
};

export default Calendars;
