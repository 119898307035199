import React, { useState, useContext } from 'react';
import {
  Container,
  Typography,
  Dialog
} from '@material-ui/core';
import { useStyles } from './TournamentSelectPage.style';
import { useHistory } from 'react-router-dom';
import TournamentBtn from './components/TournamentBtn/TournamentBtn';
import PopUp from './components/PopUp/PopUp';
import { TeamContext } from '../TeamContent';

const TournamentSelectPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { teamInfo, getTeamInfo, loading } = useContext(TeamContext);
  const [open, setOpen] = useState(false);
  const titleL = "The State Australian Selection Trial 2022";
  const subTitleL = "The Captain Selection Trial 2";
  const titleR = "The World Amateur 4BBB Champion's Tournament 2022";
  const subTitleR = "Invited teams only";
  const handleSelectState = () => {
    if (teamInfo.teamId !== null) {
      history.push("/auth/team/teaminfo");
    } else {
      history.push("/auth/team/form");
    }
  }
  const handleSelectWorld = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={classes.container}>
        <div>
          <Typography align="center" className={classes.title}>
            MY TEAM
          </Typography>
          <Typography align="center" className={classes.subTitle}>
            Please select a Tournament
          </Typography>
        </div>
        <div className={classes.btn}>
          <TournamentBtn title={titleL} subTitle={subTitleL} handleSelect={handleSelectState} getTeamInfo={getTeamInfo} />
          <TournamentBtn title={titleR} subTitle={subTitleR} handleSelect={handleSelectWorld} getTeamInfo={getTeamInfo} />
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { borderRadius: 20 }
        }}
      >
        <PopUp handleClose={handleClose} />
      </Dialog>
    </>
  )
}

export default TournamentSelectPage;
