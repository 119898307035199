import {
  createTheme,
  responsiveFontSizes,
  makeStyles
} from '@material-ui/core/styles';
import { cyan } from '@material-ui/core/colors';

let theme = createTheme({
  palette: {
    // type: 'dark',
    primary: {
      main: '#7d589f',
    },
    secondary: {
      main: '#ef7d0f',
    },
  },
  overrides: {
    MuiFormHelperText: {
      root: {
        color: '#f44336',
      },
    },
  },
  typography: {
    fontFamily: 'Poppins-Regular',
  },
});

theme = responsiveFontSizes(theme);

const useStyle = makeStyles(() => ({
  root: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary
  },
  paper: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  }
}));

export { theme, useStyle };
