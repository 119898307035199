import axios from 'axios';
import ILogin from '../common/interfaces/ILogin';
import ICreateTeam from 'src/common/interfaces/ICreateTeam';
import IResetPassword from 'src/common/interfaces/IResetPassword';
import IResendEmail from '../common/interfaces/IResendEmail';
import IVerifyEmailExists from '../common/interfaces/IVerifyEmailExists';
import IUpdateProfileValue from 'src/common/interfaces/IUpdateProfileValue';

const baseUrl = process.env.REACT_APP_WACT4_API;
// const baseUrl = "https://api.asyncedu.io/api/v1";

axios.interceptors.response.use(
  (res: any) => res,
  (error: any) => {
    const status = error.response ? error.response.status : null;
    if (status === 403) {
      localStorage.clear();
      window.location.replace('/login');
    }
    if (status === 406) {
      localStorage.clear();
      window.location.replace('/login');
    }
    return Promise.reject(error);
  },
);

// Start LoginForm
export const login = ({ email, password }: ILogin) => axios({
  method: 'post',
  url: `${baseUrl}/login`,
  data: {
    email,
    password,
  },
});

export const verifyStatus = (email: string, token: string) => axios({
  method: 'get',
  url: `${baseUrl}/login`,
  headers: { Authorization: `Bearer ${token}` },
  params: {
    email,
  },
});

export const verifyCompany = (email: string) => axios({
  method: 'get',
  url: `${baseUrl}/company`,
  headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
  params: {
    email,
  },
});
// End LoginForm

export const signup = ({ firstName, lastName, gender, phoneNumber, club, postCode, country, state, handiCap, golfLinkNumber, email, password, checkReceive }: any) => axios({
  method: 'post',
  url: `${baseUrl}/signup`,
  data: {
    firstName,
    lastName,
    gender,
    phoneNumber,
    club,
    postcode: postCode,
    country,
    state,
    handicap: handiCap,
    golfLinkNumber,
    email,
    password,
    promotionApproval: checkReceive
  },
});

export const getUserProfile = (userId: string | null) => axios({
  method: 'get',
  url: `${baseUrl}/profile/${userId}`,
  headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
});

export const updateUserProfile = (userId: string | null, { club, firstName, gender, lastName, phoneNumber, postCode, state }: IUpdateProfileValue) => axios({
  method: 'put',
  url: `${baseUrl}/profile/${userId}`,
  headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
  data: {
    firstName,
    lastName,
    phoneNumber,
    state,
    postcode: postCode,
    club,
    gender,
  },
});

export const getAllclubs = () => axios({
  method: 'get',
  url: `${baseUrl}/clubs`,
});


export const getInvitationInfo = (code: string | null) => axios({
  method: 'get',
  url: `${baseUrl}/invitations/info`,
  params: {
    code,
  },
});

export const verifyActiveUser = (code: string | null) => axios({
  method: 'post',
  url: `${baseUrl}/verify`,
  params: {
    code,
  },
});

export const resendActivationLink = ({ email }: IResendEmail) => axios({
  method: 'post',
  url: `${baseUrl}/resend`,
  params: {
    email,
  },
});
// End SignUpForm

export const createTeam = ({ userId, teamNames, grade }: ICreateTeam) => axios({
  method: 'post',
  url: `${baseUrl}/user/${userId}/team/create`,
  headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
  data: {
    userId,
    teamName: teamNames,
    grade,
  },
});

export const inviteToTeam = ({ teamId, userId, inviteeList }: any) => axios({
  method: 'post',
  url: `${baseUrl}/user/${userId}/team/${teamId}/invitation`,
  headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
  data: {
    teamId,
    inviterId: userId,
    inviteeList,
  },
});

export const acceptInvitation = (userId: string | null, teamId: string | null, id: string) => axios({
  method: 'post',
  url: `${baseUrl}/user/${userId}/team/acceptance/${id}/${teamId}`,
  headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
});

export const rejectInvitation = (userId: string | null, teamId: string | null, id: string) => axios({
  method: 'post',
  url: `${baseUrl}/user/${userId}/team/rejection/${id}/${teamId}`,
  headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
});

export const fetchTeamInfo = (userId: string | null, teamId: string | null) => axios({
  method: 'get',
  url: `${baseUrl}/user/${userId}/team/${teamId}/teaminfo`,
  headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
});

export const fetchPendingInvitationList = (userId: string | null, teamId: string | null) => axios({
  method: 'get',
  url: `${baseUrl}/user/${userId}/team/${teamId}`,
  headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
});

export const fetchInvitationMessageByUser = (userId: string | null) => axios({
  method: 'get',
  url: `${baseUrl}/user/${userId}/team`,
  headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
});

export const fetchUserInSameClub = (userId: string | null, clubId: string | null, teamId: number | null) => axios({
  method: 'get',
  url: `${baseUrl}/user/${userId}/team/${teamId}/club/${clubId}`,
  headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
});

export const createInvoice = (userId: string | null, trailId: string | null) => axios({
  method: 'post',
  url: `${baseUrl}/invoice/${userId}/${trailId}`,
  headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
});

export const getInvoice = (userId: string | null, trailId: string | null) => axios({
  method: 'get',
  url: `${baseUrl}/invoice/${userId}/${trailId}`,
  headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
});

export const getInvoiceByByte = (userId: string | null, invoiceNumber: string | null) => axios({
  method: 'get',
  url: `${baseUrl}/invoice/${userId}/${invoiceNumber}/byte`,
  headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
});

export const getInvoicePresign = (userId: string | null, invoiceNumber: string | null) => axios({
  method: 'get',
  url: `${baseUrl}/invoice/${userId}/${invoiceNumber}/presign`,
  headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
});

export const sendInvoiceEmail = (userId: string | null, invoiceNumber: string | null, email: string | null) => axios({
  method: 'get',
  url: `${baseUrl}/invoice/${userId}/sendEmail/${invoiceNumber}/${email}`,
  headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
});


// Start GetCompanyInfo and Colleagues list
export const getCompanyInfoColleagues = (email: string | null) => axios({
  method: 'get',
  url: `${baseUrl}/companies/company-info`,
  headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
  params: {
    email,
  },
});
// End GetCompanyInfo and Colleagues list

// Start pre-fill name and description of company
export const fetchCompanyProfile = (companyId: string | null) => axios({
  method: 'get',
  url: `${baseUrl}/companies/${companyId}/profile`,
  headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
});
// End prefill name and description of company

// Start companyPanel
export const getCompanyInfo = (companyId: string | null) => axios({
  method: 'get',
  url: `${baseUrl}/companies/${companyId}`,
  headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
});

export const getEmployeeInfo = (id: string | null) => axios({
  method: 'get',
  url: `${baseUrl}/companies/${id}/employees`,
  headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
});
// End companyPanel

// Start GetProjectList//
export const getProjectList = (companyId: string) => axios({
  method: 'get',
  url: `${baseUrl}/projects/${companyId}`,
  headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
});
// End GetProjectList//

// Start GetProjectInfo and ProjectUser list//
export const getProjectInfo = (projectId: string | null) => axios({
  method: 'get',
  url: `${baseUrl}/projects/${projectId}/project-info`,
  headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
});

export const fetchProjectMemberList = (projectId: string) => axios({
  method: 'get',
  url: `${baseUrl}/projects/${projectId}/members`,
  headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
});
// End GetProjectInfo and ProjectUser list//

// Start fetch todoLists
export const fetchTodoLists = (projectId: string, quantity: number) => axios({
  method: 'get',
  url: `${baseUrl}/projects/${projectId}/todolists`,
  headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
  params: {
    quantity,
  },
});
// End fetch todoLists

// Start fetch message list
export const fetchMessageList = (projectId: string) => axios({
  method: 'get',
  url: `${baseUrl}/projects/${projectId}/messages`,
  headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
});
// End fetch message list



// Start SignUpForm
export const verifyEmailExists = ({ email }: IVerifyEmailExists) => axios({
  method: 'get',
  url: `${baseUrl}/signup`,
  params: {
    email,
  },
});

export const sendForgotPasswordLinkEmail = (email: string) =>
  axios({
    method: 'post',
    url: `${baseUrl}/password`,
    params: {
      email,
    },
  });

export const resetPassword = ({ code, password }: IResetPassword) =>
  axios({
    method: 'put',
    url: `${baseUrl}/password-reset`,
    data: {
      accessToken: code,
      password,
    },
  });

export const getResetterInfo = (code: string | null) =>
  axios({
    method: 'get',
    url: `${baseUrl}/password-reset/info`,
    params: {
      code,
    },
  });


