import * as React from 'react';
import { useStyles } from './FormTeamPage.style';
import {
  Typography,
} from '@material-ui/core';
import FormTeamForm from './components/FormTeamForm/FormTeamForm';
import Notifications from './components/Notifications/Notifications';

const FormTeamPage = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.container}>
        <div className={classes.root} >
          <div>
          <Typography align="center" className={classes.title}>
              MY TEAM
            </Typography>
            <div className={classes.formTeam}>
              <FormTeamForm />
              <Notifications />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FormTeamPage;
