import React from 'react';
import { useStyles } from './SingleSchedule.style';
import ScheduleIcon from 'src/assets/pngs/icons/dashboard/scheduleIcon.png';

const SingleSchedule = () => {
  const title = 'The Captain and Team Selection Round 1';
  const classes = useStyles();
  return (
    <div className={classes.schedulecontainer}>
      <div className={classes.iconPosition}>
			    <img src={ScheduleIcon} alt="schedule" className={classes.icon} />
			</div>
      <div className={classes.info}>
        <span className={classes.title}>{title}</span>
        <span className={classes.details}>Registration is closed at 11:59 pm</span>
      </div>
    </div>
  );
};

export default SingleSchedule;
