import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: 'Poppins-regular',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.2',
      letterSpacing: 'normal',
      textAlign:'left',
      backgroundColor: '#fff',
      borderRadius: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '290px',
      margin: '5px 20px 20px 0',
      position: 'relative',
    },
    statusContainer: {
      position: 'absolute',
      backgroundColor: '#fcb040',
      borderRadius: 5,
      padding: '3px 12px',
      top: 12,
      right: 12,
    },
    status: {
      color: '#fff',
      fontSize: '11px',
      fontWeight: 600,
    },
    container: {
       display: 'flex',
       flexDirection: 'column',
       padding: '0 20px 20px 20px',
    },
    titleContainer: {
       marginTop: 10,
       display: 'flex',
       flexDirection: 'column',
       marginBottom: 10,
    },
    imgTitle: {
      width: '290px',
      height: '170px',
      borderTopLeftRadius: '20px',
      borderTopRightRadius: '20px',
    },
    dateContainer: {
       display: 'flex',
       flexDirection: 'row',
    },
    date: {
      display: 'flex',
      flexDirection: 'column',
    },
    btn: {
      position: 'relative',
      marginLeft: 'auto',
    },
    imgBtn: {
      position: 'relative',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '50px',
      height: '33px',
      cursor: 'pointer',
    },
    title: {
      fontSize: '18px',
      fontWeight: 600,
    },
    subTitle: {
     
      fontSize: '16px',
      fontWeight: 600,
    },
    info: {
      fontSize: '16px',
      fontWeight: 'normal',
     
    },
  }),
);
