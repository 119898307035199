import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titlecontainer: {
      display: 'flex',
    },
    schedules: {
      display: 'flex',
      width: '360px',
      flexDirection: 'column',
    }
  }),
);
