import React, { createContext, useState, useEffect } from 'react';
import { fetchTeamInfo, fetchPendingInvitationList, fetchInvitationMessageByUser } from 'src/utils/apiUtils';
import IMember from 'src/common/interfaces/IMember';
import IPendingList from 'src/common/interfaces/IPendingList';
interface ITeamInfoContext {
  getTeamInfo: Function;
  teamInfo: ITeamInfo;
  loading: boolean;
  pendingList: IPendingList[];
  getPendingInvitationList: Function;
  getInvitationNotificationByUser: Function;
  userInvitationNoti?: IUserInvitationNoti[];
  update: number;
  setUpdate: Function;
}

interface ITeamInfo {
  teamId: number | null;
  teamName: string;
  teamMemberList: IMember[];
}
interface IUserInvitationNoti {
  id: string;
  teamId: string;
  inviterId: string;
  inviteeId: string;
  teamName: string;
  inviterFirstName: string;
  inviterLastName: string;
  inviteeFirstName: string;
  inviteeLastName: string;
}

export const TeamContext = createContext({} as ITeamInfoContext);

export const TeamContextProvider = ({ children }: { children: JSX.Element }) => {
  const userId = localStorage.getItem('userId');
  const teamId = localStorage.getItem('teamId');
  const [update, setUpdate] = useState(0);
  const [loading, setLoading] = useState(true);
  const [teamInfo, setTeamInfo] = useState<ITeamInfo>(teamInfoInit);
  const [userInvitationNoti, setUserInvitationNoti] = useState<IUserInvitationNoti[]>();
  const [pendingList, setPendingList] = useState<IPendingList[]>(pendingListInit);

  const getTeamInfo = async () => {
    console.log("fetching the team info ...")
    try {
      const teamInfoResponse = await fetchTeamInfo(userId, teamId);
      if (teamInfoResponse.status === 200) {
        console.log("get team info successfully!")
        setTeamInfo(teamInfoResponse.data);
        setLoading(false);
        getPendingInvitationList();
      }
    } catch (error: any) {
      if (error?.response.status === 404) {
        console.log("No team info for user!")
        setLoading(false);
      }
    }
  }

  const getInvitationNotificationByUser = async () => {
    console.log("fetching user's intivation notification ... ")
    try {
      const intivationNotiResponse = await fetchInvitationMessageByUser(userId);
      if (intivationNotiResponse.status === 200) {
        console.log("get user's intivation notification successfully!")
        setUserInvitationNoti(intivationNotiResponse.data);
        setLoading(false);
      }
    } catch (error: any) {
      if (error?.response.status !== 200) {
        console.log("No intivation notification for user!")
        setLoading(false);
      }
    }
  }

  const getPendingInvitationList = async () => {
    const PendingInvitationResponse = await fetchPendingInvitationList(userId, teamId);
    if (PendingInvitationResponse.status === 200) {
      console.log("get pending list successfully!")
      setPendingList(PendingInvitationResponse.data);
    }
  }
  useEffect(() => {
    if (teamInfo.teamId === null && teamId !== "0") {
      getTeamInfo();
    }
    if (teamId === "0") {
      getInvitationNotificationByUser();
    }
  }, [update, teamId])

  const returnValue = {
    loading,
    update,
    setUpdate,
    teamInfo,
    getTeamInfo,
    pendingList,
    userInvitationNoti,
    getPendingInvitationList,
    getInvitationNotificationByUser,
  };

  return (
    <TeamContext.Provider value={returnValue}>
      {children}
    </TeamContext.Provider>
  );
};

const teamInfoInit: ITeamInfo = {
  teamId: null,
  teamName: '',
  teamMemberList: [
    {
      memberId: null,
      memberFirstName: '',
      memberLastName: '',
      membertype: '',
      golfLevel: '',
    }
  ],
};

const pendingListInit: IPendingList[] = [
  {
    teamName: "",
    inviterFirstName: "",
    inviterLastName: "",
    inviteeFirstName: "",
    inviteeLastName: ""
  }
]
