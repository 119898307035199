import React, { useState } from 'react';
import { useHistory } from 'react-router';
import {
    Button,
    Menu,
    MenuItem,
    MenuProps,
    Typography,
    Dialog,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import PopUp from '../../../TournamentSelectPage/components/PopUp/PopUp';
import useStyles from './TournamentSelector.style';

const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props: MenuProps) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));
  
const TournamentSelector = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const { title, otherTitle } = props;
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSwitch = () => {
    handleClose();
    setOpen(true);
  };

  const handleSelect = () => {
    handleClose();
    history.push('/auth/team/teaminfo')
  };
  
  const handleClosePop = () => {
    setOpen(false);
  };
  return (
    <div>
      <Button
        aria-controls="tournament-selector"
        aria-haspopup="true"                
        onClick={handleClick}
        endIcon={anchorEl ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
      >
        <Typography className={classes.title} noWrap>
          {title}
        </Typography>
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
         <MenuItem className={classes.selectBg} onClick={handleSelect} >
          <Typography className={classes.subTitle} noWrap>
            {title}
          </Typography>
        </MenuItem>
        <MenuItem className={classes.selectBg} onClick={handleSwitch} >
          <Typography className={classes.subTitle} noWrap>
            {otherTitle}
          </Typography>
        </MenuItem>
         <Dialog
            open={open}
            onClose={handleClosePop}
          >
            <PopUp handleClose={handleClosePop} />
          </Dialog>
      </StyledMenu>
    </div>
    );
}
  
export default TournamentSelector;