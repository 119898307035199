import React from 'react';
import { Typography, Grid, Hidden, Container } from '@material-ui/core';
import caption from 'src/assets/pngs/password_reset_page_caption.png';
import useStyles from './PasswordResetPage.style';
import PasswordResetForm from './components/PasswordResetForm';

const PasswordResetPage = () => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Typography component="h1" variant="h4" align="center" className={classes.title}>
        Reset password
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item md={5} sm={6} xs={8}>
          <PasswordResetForm />
        </Grid>
        <Hidden mdDown>
          <Grid item md={5} container className={classes.img_container}>
            <img src={caption} alt="resetPassword" className={classes.background_img} />
          </Grid>
        </Hidden>
      </Grid>
    </Container>
  );
};

export default PasswordResetPage;
