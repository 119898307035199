import React from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme:Theme) => ({
  label:{
    marginBottom:'15px',
  },
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#511d62",
    // textAlign: 'left',
  },}
}));

interface dataType{
    value:string;
    label:string;
};

interface PropsType{
    name: string;
    label: string;
    data: dataType[];
    fullWidth:boolean;
  };


  const SelectField = (props: PropsType) => {
  const classes = useStyles();
  const { label, data, fullWidth } = props;
  const [field, meta] = useField(props);
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;
  function _renderHelperText() {

    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  return (
    <FormControl fullWidth error={isError} variant="outlined" >
      <div className={classes.label}>{label}</div>
      {/* <InputLabel className={classes.labelText}>{label}</InputLabel> */}
  
      <Select {...field} className={classes.root} value={selectedValue ? selectedValue : ''}>
        {data.map((item:any, index:any) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {_renderHelperText()}
    </FormControl>
  );
}

// SelectField.defaultProps = {
//   data: []
// };

// SelectField.propTypes = {
//   data: PropTypes.array.isRequired
// };

export default SelectField;
