import React, { useContext, useState } from 'react';
import TeamMemberCard from './components/TeamMemberCard/TeamMemberCard';
import IMember from 'src/common/interfaces/IMember';
import useStyles from './TeamPanel.style';
import { TeamContext } from '../../../TeamContent';
import { fetchUserInSameClub } from 'src/utils/apiUtils';

interface IOptionalList {
  remainingAmount: number;
  optionalUserList: IOptionalUser[];
}
interface IOptionalUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
}
interface TeamInfo {
  trail: number;
}
const TeamPanel = ({ trail }: TeamInfo) => {
  const classes = useStyles();
  const teamSize = 4;
  const [optionalUserList, seOptionalUserList] = useState<IOptionalList>();
  const { teamInfo } = useContext(TeamContext);
  const { teamId, teamMemberList } = teamInfo;
  const userId = localStorage.getItem('userId');
  const clubId = localStorage.getItem('clubId');
  localStorage.setItem('trailId', trail.toString());
  const fetchUserOptionList = async () => {
    const userOptionListRes = await fetchUserInSameClub(userId, clubId, teamId);
    if (userOptionListRes.status === 200) {
      seOptionalUserList(userOptionListRes.data);
    }
  }
  const emptyMemberList: IMember = {
    memberId: null,
    memberFirstName: '',
    memberLastName: '',
    membertype: '',
    golfLevel: '',
  }
  for (let i = 0; i < teamSize; i++) {
    teamMemberList?.push(emptyMemberList);
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.cardList}>
          {teamMemberList?.slice(0, teamSize).map((item: IMember, index) => (
            <div
              className={classes.card}
              key={index}
            >
              <TeamMemberCard
                fetchUserOptionList={fetchUserOptionList}
                remainingAmount={optionalUserList?.remainingAmount}
                optionalUserList={optionalUserList?.optionalUserList}
                teamId={teamId}
                memberId={item.memberId}
                memberFirstName={item.memberFirstName}
                memberLastName={item.memberLastName}
                membertype={item.membertype}
                golfLevel={item.golfLevel}
              />
            </div>
          ))}
        </div>
        {/* <div className={classes.notification}>
          <InvitationNotification pendingList={pendingList} />
        </div> */}
      </div>
    </>
  )
}

export default TeamPanel;
