import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    header: {
      backgroundColor: '#ecdded',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        height: '70px',
      },
      [theme.breakpoints.up('md')]: {
        height: '80px',
      },
    },
    tab: {
      textTransform: 'none',
      minWidth: 20,
      paddingLeft: 12.5,
      paddingRight: 12.5,
      fontFamily: 'Poppins-Medium',
      fontWeight: 500,
      fontSize: 18,
      color: '#4e2360',
      opacity: 1,
      textDecoration: 'none',
      paddingBottom: '26px',
      '&:hover': {
        color: '#371a43',
        borderBottom: '2px solid #ef7d0f',
      },
    },
    link: {
      display: 'flex',
      flexDirection: 'row',
    },
    img: {
      [theme.breakpoints.down('md')]: {
        height: '50px',
      },
      [theme.breakpoints.up('md')]: {
        height: '60px',
      },
    },
    menu_button: {
      display: 'flex',
      alignItems: 'center',
    },
    menu_text:{
      fontFamily: 'Poppins-Regular',
      fontWeight: 600,
      fontSize: 12,
      marginLeft: '8.4px',
    },
    header_item: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
      [theme.breakpoints.up('md')]: {
        display: 'inline',
      },
    },
    menu: {
      [theme.breakpoints.down('md')]: {
        display: 'inline',
      },
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    menu_item:{
      textDecoration: 'none',
      color: '#1c0f25',
    },
  }),
);
