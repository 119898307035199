import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    promotion: {
      display: 'flex',
      marginTop: '80px',
      justifyContent: 'space-around',

    },
    rightpanel: {
      paddingLeft: '15px',
      paddingTop: '62px',
      position: 'fixed',
      right: 0,
      backgroundColor: '#eae7ee',
      // border: 'solid 2px black',
    },
  }),
);
