import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles =(atBottom:any) => makeStyles((theme: Theme) => 
  createStyles({
    container: {
      backgroundColor: '#e0d8e5',
      padding: '30px 15px',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        padding: '30px 20px',
      }
    },
    calender: {
      textAlign: 'center',
      marginTop: 10,
      marginBottom: 10,
    },
    title: {
      fontFamily: 'Poppins-SemiBold',
      fontSize: 20,
      fontWeight: 600,
      letterSpacing: 10,
      lineHeight: 1.2,
      color: '#333',
    },
    schedule: {
      textAlign: 'center',
    },
    img: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    icon: {
      width: 35,
      height: 35,
    },
    calendarIcon: {
      width: 45,
      height: 45,
      position: 'fixed',
      bottom: atBottom? 100 : 20,
      right: 20,
    },
    paper: {
      borderRadius: 10
    },
  })
)()