import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& h1': {
        marginBottom: 40,
      }
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }
  })
);

export default useStyles;