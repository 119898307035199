import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = (isFullWidth: Boolean) =>  makeStyles((theme: Theme) => createStyles({
  foot: {
    position:'absolute',
    bottom: isFullWidth? '0': '74px',
    width: isFullWidth? '100%': '50%',
    // width: 'calc(100% - 852px)',
    backgroundColor: 'RGB(241, 245, 248)',
    // position: 'fixed',
    // bottom: 0,
    borderTop: '1px solid #dddddd',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  half: {
  },
  link: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop:'14px',
    paddingBottom:'14px',
  },
  copyRight: {
    color: '#1C0F25',
    paddingTop:'13px',
    maxWidth: '1290px',
    width: '90%',
    textAlign: 'center',
    fontFamily: 'Poppins-regular',
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '8px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '10px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '12px',
    },
  },
  item: {
    paddingLeft: '9px',
    fontFamily: 'Poppins-bold',
    paddingRight: '9px',
    color: 'RGB(86, 42, 103)',
    [theme.breakpoints.down('xs')]: {
      fontSize: '8px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '10px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '12px',
    },
  },
  herf: {
    textDecoration: 'none',
    color: '#333',
  },
}))();

export default useStyles;
