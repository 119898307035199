import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';

const drawerWidth = 240;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      position: 'relative',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(8) + 3,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(8) + 3,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    closeIcon: {
      width: '30px',
      height: '30px',
    },
    menu: {
      marginTop: 15,
    },
    divider: {
      marginBottom: 20,
    },
    btnlist: {
      marginTop: 280,
      textAlign: 'center',
    },
    btn: {
      border: 'none',
      width: '120px',
      height: '40px',
      borderRadius: '10px',
      backgroundColor: '#fcb040',
      fontFamily: 'Poppins-regular',
      fontSize: '15px',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.2',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#fff',
      cursor: 'pointer',
    },
  }),
);
