import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: 30,
    //   minHeight: 'calc(100vh - 152px)',
    },
    title: {
      color: '#333',
      fontWeight: 600,
      letterSpacing: 6.8,
      fontSize: '35px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '27px',
      },
    },
    subTitle: {
    fontFamily: 'Poppins-regular',
    color: '#333',
    fontWeight: 600,
    fontSize: '25px',
    [theme.breakpoints.down('xs')]: {
        fontSize: '17px',
    },
    },
    btn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '5rem',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
    },
}));
