import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    icon: {
      marginTop: 12,
      width: 40,
      height: 40,
    },
    titlecontainer: {
      display: 'flex',
    },
    competitions: {
      display: 'flex',
      flexWrap: 'wrap',
      
    }
  }),
);
