import * as React from 'react';
import {
  Typography,
} from '@material-ui/core';
import PopUpIcon from 'src/assets/pngs/icons/pop-up-window.png';
import CloseIcon from 'src/assets/pngs/icons/close-button.png';
import { useStyles } from './PopUp.style';

const PopUp = (props: any) => {
  const { handleClose } = props;
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <img className={classes.img} src={PopUpIcon} alt="pop up icon" />
        <div className={classes.textContainer}>
          <Typography className={classes.title}>This tournament is for</Typography>
          <Typography className={classes.subTitle}>Invited teams only</Typography>
          <Typography className={classes.content}>Please click The State Australian Selection Trial 2022 to earn an invitation to represent your state</Typography>
        </div>
        <div onClick={handleClose} className={classes.btnContainer}>
          <img className={classes.btn} src={CloseIcon} alt="close icon" />
        </div>
      </div>
    </>
  )
}

export default PopUp;