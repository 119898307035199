import * as Yup from 'yup';
import checkoutFormModel from './checkoutFormModel';
const {
  formField: {
    firstName,
    lastName,
    gender,
    checkOver18,
    state,
    postCode,
    golfLinkNumber,
    handiCap,
    emailAddress,
    confirmEmailAddress,
    password,
    confirmPassword,
    checkAgree,
    club
  }
} = checkoutFormModel;

const postCodeRegEx = /^[1-9]{1}[0-9]{3}$/;
const golfLinkNumberRegEx = /^[0-9]{10}$/;
const handiCapRegEx = /^\d{1,2}((\.\d{1,2})?)$|^\+[0-9]{1,2}$/; 
const passwordRegEx = /^(?=\S*[a-zA-Z])(?=\S*[0-9#!"$%&'()*+,-./:;<=>?@[\]^_`{|}~])\S{8,}$/;
export default [
  Yup.object().shape({
    [firstName.name]: Yup.string()
      .trim()
      .required(`${firstName.requiredErrorMsg}`),
    [lastName.name]: Yup.string().trim().required(`${lastName.requiredErrorMsg}`),
    [gender.name]: Yup.string()
      .nullable()
      .required(`${gender.requiredErrorMsg}`),
    [checkOver18.name]: Yup.boolean().oneOf([true], 'this field is requied'),
    [club.name]:Yup.string()
    .trim(). required(`${club.requiredErrorMsg}`)
  }),

  Yup.object().shape({
    [state.name]: Yup.string()
      .nullable()
      .required(`${state.requiredErrorMsg}`),

    [postCode.name]: Yup.string()
      .nullable()
      .required(`${postCode.requiredErrorMsg}`)
      .matches(postCodeRegEx, postCode.invalidErrorMsg),

    [golfLinkNumber.name]: Yup.string()
      .trim()
      .nullable()
      .required(`${golfLinkNumber.requiredErrorMsg}`)
      .matches(golfLinkNumberRegEx, golfLinkNumber.invalidErrorMsg),

    [handiCap.name]: Yup.string()
      .trim()
      .required(`${handiCap.requiredErrorMsg}`)
      .matches(handiCapRegEx, handiCap.invalidErrorMsg)
  }),

  Yup.object().shape({
    [emailAddress.name]: Yup.string()
      .trim()
      .email(`${emailAddress.invalidErrorMsg}`)
      .required(`${emailAddress.requiredErrorMsg}`),
    [confirmEmailAddress.name]: Yup.string()
      .oneOf([Yup.ref(emailAddress.name)], 'your Email address does not match')
      .required(`${confirmEmailAddress.requiredErrorMsg}`),
    [password.name]: Yup.string()
      .required(`${password.requiredErrorMsg}`)
      .matches(passwordRegEx, password.invalidErrorMsg),
    [confirmPassword.name]: Yup.string()
    .oneOf([Yup.ref(password.name)], 'your password does not match')
    .required(`${confirmPassword.requiredErrorMsg}`),
    [checkAgree.name]: Yup.boolean().oneOf([true], 'this field is required')
  })
];
