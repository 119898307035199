import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { OTHER_ERROR } from 'src/common/constants/ErrorMessages';
import * as apiUtils from 'src/utils/apiUtils';
import IResetPasswordForm from 'src/common/interfaces/IResetPasswordForm';
import * as Yup from 'yup';

const usePasswordResetForm = (code:string | null) => {
  const initialValues: IResetPasswordForm = {
    password: '',
    confirmPassword: '',
  };
  const history = useHistory();

  const validationPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .matches(
        /^(?=\S*[a-zA-Z])(?=\S*[0-9#!"$%&'()*+,-./:;<=>?@[\]^_`{|}~])\S{8,}$/,
        'Password is invalid! At least 8 characters with 1 alphabet and 1 non-letter character',
      )
      .max(128, 'Your password is too long!')
      .required('Password required!'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords do not match')
      .required('Please confirm your password'),
  });

  const [warning, setWarning] = useState({
    shown: false,
    message: '',
  });


  const [loginInfo, setLoginInfo] = useState({
    email: '',
  });

  const handleOnSubmit = async ({ password } : IResetPasswordForm) => {
    setWarning({
      shown: false,
      message: '',
    });
    try {
      const resetResponse = await apiUtils.resetPassword({ code, password });
      if (resetResponse.status === 200) {
        const { email } = loginInfo;
        const loginResponse = await apiUtils.login({ email, password });
        if (loginResponse.status === 200) {
          const token = loginResponse.data.accessToken;
          const statusResponse = await apiUtils.verifyStatus(email, token);
          if (statusResponse.status === 200) {
            localStorage.setItem('accessToken', loginResponse.data.accessToken);
            localStorage.setItem('email', email);
            localStorage.setItem('userId', loginResponse.data.id);
            localStorage.setItem('clubId', loginResponse.data.clubId);
            localStorage.setItem('firstName', loginResponse.data.firstName);
            localStorage.setItem('lastName', loginResponse.data.lastName);
            history.push('/auth/dashboard');
          }
        }
      }
    } catch (err: any) {
      if (err.response) {
        if (err.response.status === 409 || err.response.status === 404) {
          setWarning({
            shown: true,
            message: 'Your email does not exist or be activated.',
          });
        }
      } else {
        setWarning({
          shown: true,
          message: OTHER_ERROR,
        });
      }
    }
  };
  
  useEffect(() => {
    let isSubscribed = true;
    localStorage.clear();
    if (code === null) {
      history.push('/login');
    }
    const getCodeStatus = async () => {
      try {
        const resp = await apiUtils.getResetterInfo(code);
        if (isSubscribed && resp.status === 200) {
          setLoginInfo({
            email: resp.data.email,
          });
        }
      } catch (err: any) {
        if (err.response) {
          history.push('/login');
        }
      }
    };
    getCodeStatus();
    return () => { isSubscribed = false; };
  }, []);
  return { initialValues, validationPasswordSchema, handleOnSubmit, warning, history };
};

export default usePasswordResetForm;
