import React from 'react';
import { useStyles } from './UpcomingCompetitions.style';
import SingleCompetition from './components/SingleCompetition/SingleCompetition';

const UpcomingCompetitions = () => {
  const trial1 = {
    status: '1',
    team: 'Pending',
    startdate: '1st Febuary 2022',
  };
  const trial2 = {
    status: '2',
    team: 'Pending',
    startdate: '1st May 2022',
  };
   const trial3 = {
    status: '3',
    team: 'Pending',
    startdate: '1st August 2022',
  };
  const classes = useStyles();
  return (
    <>
      <div className={classes.titlecontainer}>
        <h2>Up coming Competitions</h2>
      </div>
      <div className={classes.competitions}>
        <SingleCompetition competition={trial1} />
        <SingleCompetition competition={trial2} />
        <SingleCompetition competition={trial3} />
      </div>
    </>
  );
};

export default UpcomingCompetitions;
