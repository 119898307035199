import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  container: {
    [theme.breakpoints.down('md')]: {
      marginTop: '100px',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '150px',
    },
  },
  title: {
    fontFamily: 'Poppins-Bold',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      fontSize: '1.25rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.6rem',
    },
  },
  form: {
    height: '650px',
    width: '400px',
    [theme.breakpoints.down('md')]: {
      margin: 'auto',
      padding: theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
    },
  },
  img_container: {
    height: '650px',
    width: '400px',
    alignItems: 'center',
  },
  background_img: {
    width: '100%',
  },
}));
