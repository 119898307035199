import React from 'react';
import { Typography, Grid, Hidden, Container } from '@material-ui/core';
import caption from 'src/assets/pngs/login_page_caption.png';
import LoginForm from './components/LoginForm/LoginForm';
import useStyles from './Login.style';

const Login = () => {
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <Typography align="center" className={classes.title}>Log in for WACT4BBB</Typography>
      <Grid container direction="row" justifyContent="space-around" alignItems="center">
        <Grid item md={5}>
          <LoginForm />
        </Grid>
        <Hidden smDown>
          <Grid item md={5} container className={classes.img_container}>
            <img src={caption} alt="login_caption" className={classes.background_img} />
          </Grid>
        </Hidden>
      </Grid>
    </Container>
  );
};
export default Login;
