import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      marginTop: 12,
      width: 40,
      height: 40,
    },
    titlecontainer: {
      display: 'flex',
      paddingLeft: '20px',
    },
    datefield: {
      maxWidth: '700px',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '20px',
      paddingLeft: '15px',
      paddingRight: '15px',
    },
    dayname: {
      textAlign: 'center',
    },
    daynumber: {
      textAlign: 'center',
      fontSize: '30px',
    },
    calendar: {
    },
  }),
);
