import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      marginTop: 12,
      width: 40,
      height: 40,
    },
    datefield: {
      maxWidth: '700px',
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 20,
      marginBottom: 20,
      paddingLeft: '15px',
      paddingRight: '15px',
      [theme.breakpoints.down('xs')]: {
        width: 350,
      }
    },
    dayname: {
      textAlign: 'center',
    },
    daynumber: {
      textAlign: 'center',
      fontSize: '30px',
    },
    calendar: {
    },
  }),
);
