import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    btnContainer: {
      display: 'flex',
      flexDirection: 'row',
      paddingRight: 11,
    },
    btn: {
      padding: 0,
      color: '#b3b3b3',
      height: 40,
      width: 40,
    },
    notify: {
      display: 'flex',
      // flexDirection: 'row',
      flexWrap: 'wrap',

      // justifyContent: 'space-between',
    },
}),);
