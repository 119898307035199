import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '60vw',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& h1': {
        marginBottom: 40,
      },
      '& h2': {
        width: '70%',
        marginBottom: 20,
      },
    },
    info: {
      padding: 50,
      marginTop: 100,
      borderRadius: 10,
      fontSize: '1.4rem',
    },
    container: {
      width: '70%',
      backgroundColor: 'white',
      borderRadius: 10,
      color: '#511d62',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 40,
    },
    invoice: {
      backgroundColor: '#511d62',
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      fontSize: 20,
      fontWeight: 'bold',
      padding: 40,
    },
    icon: {
      marginRight: 10,
    },
    detail: {
      display: 'flex',
      padding: '20px 0 20px 40px',
      '& dl': {
        width: '40%',
        color: 'black',
        '& dt': {
          width: '40%',
          margin: 0,
          padding: 0,
          float: 'left',
          fontSize: 15,
          fontWeight: 'bolder',
        },
        '& dd': {
          width: '60%',
          margin: 0,
          padding: 0,
          float: 'left',
          fontSize: 15,
        },
      },
    },
    status: {
      width: '25%',
      display: 'flex',
      backgroundColor: '#d4c9da',
      borderRadius: 10,
      justifyContent: 'space-around',
      alignSelf: 'center',
      padding: 10,
      marginLeft: 60,
    },
    download: {
      width: '25%',
      color: '#f9ad40',
      fontWeight: 'bolder',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      '& a': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        textDecoration: 'none',
        color: '#f9ad40',
      },
    },
    downloadicon: {
      width: '15%',
    },
    notice: {
      textAlign: 'center',
      fontStretch: 'normal',
      fontStyle: 'normal',
      '& span': {
        fontWeight: 'bold',
      },
    },
    sendcontainer: {
      width: '70%',
      display: 'flex',
      justifyContent: 'space-between',
      borderRadius: 10,
      padding: 40,
    },
    email: {
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      '& dl': {
        width: '100%',
        color: 'black',
        display: 'flex',
        flexDirection: 'column',
        '& dt': {
          width: '20%',
          margin: 0,
          padding: 0,
          float: 'left',
          fontSize: 15,
          fontWeight: 'bolder',
        },
        '& dd': {
          width: '50%',
          margin: 0,
          padding: 0,
          float: 'left',
          fontSize: 15,
          '& span': {
            fontWeight: 'bold',
          },
          '& input': {
            border: 'solid 2px #511d62',
            borderRadius: 5,
            width: '15vw',
            height: '3vh',
            marginTop: 10,
            '&::placeholder': {
              color: '#511d62',
              paddingLeft: 10,
            }
          },
        },
      },
    },
    btn: {
      width: '25%',
      alignSelf: 'center',
      fontSize: 15,
      fontWeight: 600,
      backgroundColor: '#f8af41',
      border: 'none',
      borderRadius: 5,
      color: '#fff',
      padding: '10px 20px',
      cursor: 'pointer',
      transitionDuration: '0.4s',
      
    },
  })
);

export default useStyles;