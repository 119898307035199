import React from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  helperText: {
    color: '#F44336',
    marginLeft: '14px'
  },
  labelText : {
    textAlign: 'left'
  }
}));
function CheckboxField(props) {
  const classes = useStyles();
  const { label, ...rest } = props;
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;
  const { value } = meta; //meta.value
  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return <FormHelperText className={classes.helperText}>{error}</FormHelperText>;
    }
  }

  function _onChange(e) {
    setValue(e.target.checked);
  }

  return (
    <FormControl {...rest}>
      <FormControlLabel
        value={value}
        checked={value}
        control={<Checkbox {...field} onChange={_onChange} />}
        label={label}
        className={classes.labelText}
      />
      {_renderHelperText()}
    </FormControl>
  );
}
// SelectField.defaultProps = {
//   data: []
// };

// SelectField.propTypes = {
//   data: PropTypes.
// };

export default CheckboxField;
