import * as React from 'react';
import { useStyles } from './TeamHeader.style';
import TournamentSelector from '../TournamentSelector/TournamentSelector';

interface ITeamHeader {
  teamName: string,
}

const TeamHeader = ({ teamName } : ITeamHeader) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.container}>
        <div className={classes.textContainer}>
          <span className={classes.title}>MY TEAM</span>
          <TournamentSelector
            className={classes.subTitle}
            title={"The State Australian Selection Trial 2022"}
            otherTitle={"The World Amateur 4BBB Champion's Tournament 2022"}
          />
        </div>
        <div className={classes.subContainer}>
          <div className={classes.teamContainer}>
            <span className={classes.label}>Current Team</span>
            <span className={classes.teamName}>{teamName}</span>
          </div>
          <div className={classes.btnContainer}>
            <button className={classes.btnMore}>More</button>
            <button className={classes.btnDisband}>Disband</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default TeamHeader;