import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navbar: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: '16px',
      paddingRight: '16px',
    },
    link: {
      display: 'flex',
      flexDirection: 'row',
      textDecoration: 'none',
    },
    textPosition: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    text: {
      textTransform: 'none',
      textDecoration: 'none',
      fontFamily: 'Poppins-regular',
      fontWeight: 600,
      lineHeight: 1.2,
      fontSize: 15,
      color: '#333',
    },
    img: {
      width: '70px',
      height: '38px',
    }
  }),
);
