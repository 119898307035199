import React from 'react';
import { Grid } from '@material-ui/core';
import {
  InputField,
  PasswordField,
  CheckboxField,
  CheckboxLinkField
} from '../../FormFields';

export default function LoginInfo(props) {
  const {
    formField: { emailAddress, confirmEmailAddress, password, confirmPassword, checkReceive, checkAgree}
  } = props;
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputField
            name={emailAddress.name}
            label={emailAddress.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            name={confirmEmailAddress.name}
            label={confirmEmailAddress.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordField
            name={password.name}
            label={password.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordField
            name={confirmPassword.name}
            label={confirmPassword.label}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <CheckboxField name={checkReceive.name} label={checkReceive.label} />
        </Grid>

        <Grid item xs={12}>
          <CheckboxLinkField name={checkAgree.name} label={checkAgree.label} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
