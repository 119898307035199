import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notificationContainer: {
      marginRight: '28px',
      backgroundColor: '#fff',
      marginTop: 20,
      minWidth: '437px',
      display: 'flex',
      borderRadius: 20,
      padding: 6,
    },
    info: {
      marginLeft: 15,
      marginTop: 12,
      marginBottom: 12,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    title: {
      fontFamily: 'Poppins-regular',
      fontSize: '15px',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.2',
      letterSpacing: 'normal',
      textAlign: 'left',
      color: '#333',
    },
    details: {
      fontFamily: 'Poppins-regular',
      fontSize: '15px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.2',
      letterSpacing: 'normal',
      textAlign: 'left',
      color: '#333',
    },
    iconPosition: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 15,
      paddingRight: 5,
    },
    icon: {
      color: '#999999',
      width: '60px',
      height: '41px'
    },
  }),
);
