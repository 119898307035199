import React, { useState } from 'react';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import {
  Drawer,
  List,
  CssBaseline,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  useMediaQuery,
  Icon,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useStyles } from './SideBarMobile.style';
import { useHistory } from 'react-router-dom';
import MenuItem from '../SideBar/components/MenuItem/MenuItem';
import menuItems from '../SideBar/menuItems';
import BackHomeIcon from 'src/assets/pngs/icons/sidebar/backhome.png';
import LogoHeader from '../SideBar/components/LogoHeader/LogoHeader';
import closeIcon from 'src/assets/pngs/icons/sidebar/closeIcon.png';

const SideBarMobile = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const WpUrl = process.env.REACT_APP_WP_URL;
  console.log(open)
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const closeNavigation = () => {
    if (matches) {
      setOpen(false);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    history.push('/login');
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <>
        <div>
          <IconButton onClick={handleDrawerOpen}>
            <MenuIcon />
          </IconButton>
        </div>
      </>
      {open &&
            <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            {open ?
          <>
            <div className={classes.toolbar}>
              <IconButton onClick={handleDrawerClose}>
                <img className={classes.closeIcon} src={closeIcon} alt="close icon" />
                {/* {theme.direction === 'rtl' ? <HighlightOffIcon /> : <HighlightOffIcon />} */}
              </IconButton>
            </div>
          </> :
          <>
            <div className={classes.toolbar}>
              <IconButton onClick={handleDrawerOpen}>
                <MenuIcon />
              </IconButton>
            </div>
          </>
        }
            <LogoHeader/>
            <List className={classes.menu}>
              {menuItems.map((item) => (
                <MenuItem
                  key={item.path}
                  label={item.label}
                  path={item.path}
                  icon={item.icon}
                  activeIcon={item.activeIcon}
                  onClick={closeNavigation}
                />
              ))}
            </List>
            <Divider className={classes.divider} />
              <MenuItem
                label={'Back to Home'}
                path={WpUrl}
                icon={BackHomeIcon}
                activeIcon={BackHomeIcon}
                onClick={closeNavigation}
            />
            <div className={classes.btnlist}>
              <button onClick={handleLogout} className={classes.btn}>Log Out</button>
            </div>
          </Drawer>
      }
    </div >
  );
}

export default SideBarMobile;
