import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      marginBottom: 20,
      display: 'flex',
      justifyContent: 'space-between',
    },
    details: {
      width: '30%',
      borderRadius: 5,
      backgroundColor: 'white',
      padding: '5px 15px',
    },
    titlebanner: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    icon: {
      marginTop: 15,
      cursor: 'pointer',
      color: 'black',
    },
    detailbanner: {
      display: 'flex',
      flexDirection: 'column',
      '& h4': {
        marginBottom: 0,
      },
      '& div': {
        marginBottom: 5,
      }
    },
    club: {
      marginTop: 20,
    }
  }),
);