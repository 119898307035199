import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    promotion: {
      width: '100%',
      height: 100,
      // borderRadius: 10,
      // border: 'solid 2px black',
    },
    image: {
      borderRadius: 10,
      width: '100%',
      height: '15vh',
      objectFit: 'cover',
    }
  }),
);