import DashboardIcon from 'src/assets/pngs/icons/sidebar/dashboard.png';
import DashboardActiveIcon from 'src/assets/pngs/icons/sidebar/dashboardActive.png';

import CompetitionsIcon from 'src/assets/pngs/icons/sidebar/competitions.png';
import CompetitionsActiveIcon from 'src/assets/pngs/icons/sidebar/competitionsActive.png';

import TeamsIcon from 'src/assets/pngs/icons/sidebar/teams.png';
import TeamsActiveIcon from 'src/assets/pngs/icons/sidebar/teamsActive.png';

import NotificationsIcon from 'src/assets/pngs/icons/sidebar/notifications.png';
import NotificationsActiveIcon from 'src/assets/pngs/icons/sidebar/notificationsActive.png';

import PaymentIcon from 'src/assets/pngs/icons/sidebar/payment.png';
import PaymentActiveIcon from 'src/assets/pngs/icons/sidebar/paymentActive.png';

import ProfileIcon from 'src/assets/pngs/icons/sidebar/profile.png';
import ProfileActiveIcon from 'src/assets/pngs/icons/sidebar/profileActive.png';

interface IRoutes {
  label: string,
  path: string,
  icon: any,
  activeIcon: any,
}

const menuItems: IRoutes[] = [
  {
    label: "Dashboard",
    path: "/auth/dashboard",
    icon: DashboardIcon,
    activeIcon: DashboardActiveIcon,
  },
  {
    label: "My competitions",
    path: "/auth/competitions",
    icon: CompetitionsIcon,
    activeIcon: CompetitionsActiveIcon,
  },
  {
    label: "My team",
    path: "/auth/team/teamselect",
    icon: TeamsIcon,
    activeIcon: TeamsActiveIcon,
  },
  {
    label: "My notifications",
    path: "/auth/notifications",
    icon: NotificationsIcon,
    activeIcon: NotificationsActiveIcon,
  },
  {
    label: "My payment",
    path: "/auth/payment/invoice",
    icon: PaymentIcon,
    activeIcon: PaymentActiveIcon,
  },
  {
    label: "My profile",
    path: "/auth/profile",
    icon: ProfileIcon,
    activeIcon: ProfileActiveIcon,
  },
];

export default menuItems;
