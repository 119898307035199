import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      padding: 50,
      textAlign: 'center',
    },
    dialogContentText: {
      color: 'black',
      fontSize: '1.3rem',
    },
    btncontainer: {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: 20,
    },
    closebtn: {
      width: 150,
      height: 50,
    },
  }),
);

export default useStyles;
