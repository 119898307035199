import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { ErrorMessage } from 'formik';
import {
  InputAdornment,
} from '@material-ui/core';
import * as apiUtils from 'src/utils/apiUtils';
import notificationIcon from 'src/assets/svgs/notificationIcon.svg';
import useStyles from '../PasswordForgotForm.style';

export const useRecoveryPassword = () => {
  const validationSchema = Yup.object().shape({
    email: Yup.string().trim().required('Email Required!').email('Please enter a valid email'),
  });

  const history = useHistory();
  const [warning, setWarning] = useState({
    shown: false,
    message: '',
  });

  const handleOnSubmit = useCallback(async ({ email }) => {
    setWarning({
      shown: false,
      message: '',
    });

    try {
      const recoveryResponse = await apiUtils.sendForgotPasswordLinkEmail( email);
      if (recoveryResponse.status === 200) {
        setWarning({
          shown: false,
          message: '',
        });
        history.push('/password-email');
      }
    } catch (error:any) {
      if (error.response) {
        if (error.response.status === 409 || error.response.status === 404) {
          setWarning({
            shown: true,
            message: 'Your email does not exist or be activated.',
          });
        } else {
          setWarning({
            shown: true,
            message: 'Oops! Something went wrong.',
          });
        }
      }
    }
  }, [history]);

  return { handleOnSubmit, warning, validationSchema };
};

export const useExtraFormConf = () => {
  const classes = useStyles();
  const getStartAdornment = useCallback((component: JSX.Element) => ({
    startAdornment: (
      <InputAdornment position="start">
        {component}
      </InputAdornment>
    ),
  }), []);
  const getErrorMsg = useCallback((name: string) => (
    <ErrorMessage name={name}>
      { (msg) => (
        <span className={classes.error}>
          <span className={classes.notification_img}>
            <img src={notificationIcon} alt="notificationIcon" />
          </span>
          <span className={classes.errormessage}>{msg}</span>
        </span>
      )}
    </ErrorMessage>
  ), [classes.error, classes.errormessage, classes.notification_img]);
  return { getStartAdornment, getErrorMsg };
};
